<template>
	<div class="card" :class="{ 'in-front' : active }" ref="container" v-if="displayCard(status, list)">
		<div class="content" :scroll-y="false">
			<button class="open-job-desktop" @click="showInfo"></button>
			<div class="card__inner" data-theme="theme-2">
				<img class="card__image" v-if="cardData.companyLogo != null" :src="cardData.companyLogo">
				<div class="card__image card__image--no-image" v-if="cardData.companyLogo == null">
					<p>LOGO<br>MISSING</p>
				</div>

				<p class="card__title">{{cardData.title}}&nbsp;</p>

				<div class="card__details">
					<p>{{cardData.type}}&nbsp;</p>
					<p>{{cardData.location}}&nbsp;</p>
				</div>
				
				<div class="actions" :class="posClass">
					<!--button class="save" @click="save" :disabled="displaySaveBtn(list)"></button-->
					<button class="discard" @click="discard" :disabled="displayDiscardBtn(list)"></button>
					<button class="info" @click="showInfo"></button>
					<button class="apply" @click="apply" :disabled="displayApplyBtn(list)"></button>
					
				</div>
			</div>

		</div>
	</div>
</template>

<script>
// import { IonContent, IonPage } from '@ionic/vue';
import { ref, onMounted } from 'vue';
import events from '../events';
import { gsap } from 'gsap';
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { mapState } from 'vuex';
gsap.registerPlugin(Draggable, MotionPathPlugin);
import { store } from "../store/store";

const swipe = (container, id) => {
	if (!container) {
		return false;
	}

	const card = container;

	let startX = 0;
	let startY = 0;
	let movedX = 0;
	let movedY = 0;
	card.addEventListener('touchstart', function (e) {
		card.classList.add('not-moving');
		startX = e.touches[0].clientX - 0;
		startY = e.touches[0].clientY - 0;
	});

	card.addEventListener('touchmove', (e) => {
		const clientX = e.touches[0].clientX - startX;
		const clientY = e.touches[0].clientY - startY;
		const xMulti = clientX * 0.03;
		const yMulti = clientY / 120;
		const rotate = xMulti * yMulti;

		movedX = clientX;
		movedY = clientY;

		card.style.transform = 'rotate(' + rotate + 'deg) scale(1)';
		card.style.top = clientY + 'px';
		card.style.left = clientX + 'px';

		if (movedX > 30) {
			events().emitter.emit('choice', 'apply');
		} else if (movedX < -30) {
			events().emitter.emit('choice', 'discard');
		} else {
			if (movedY < -80) {
				events().emitter.emit('choice', 'save');
			} else {
				events().emitter.emit('choice', '');
			}
		}
	});

	card.addEventListener('touchend', () => {
		card.classList.remove('not-moving');
		
		if (movedX > 30) {
			card.style.top = movedY + 'px';
			card.style.left = '500px';

			events().emitter.emit('setCard', {
				'save': true,
				'list': 'apply',
				'id': id
			});
		} else if (movedX < -30) {
			card.style.top = movedY + 'px';
			card.style.left = '-500px';

			events().emitter.emit('setCard', {
				'save': false,
				'id': id
			});
		} else {
			if (movedY < -80) {
				card.style.left = '0px';
				card.style.top = '-1000px';

				events().emitter.emit('setCard', {
					'save': true,
					'list': 'saved',
					'id': id
				});
			} else {
				card.style.left = '0px';
				card.style.top = '0px';
				card.style.transform = 'rotate(0deg) scale(1)';
			}
		}

		events().emitter.emit('choice', '');
	});
}

export default {
	name: 'card',
	components: {
		// IonContent,
		// IonPage,
		// Modal
	},
	props: {
		cardData: Object,
		activeCardIndex: Number,
		status: String,
		list: {
			type: String,
			default: ''
		},
		key: Number
	},
	data: () => {
		return {
			posClass: '',
			info: false
		}
	},
	computed: {
		active: function () {
			return this.$props.activeCardIndex === this.$.vnode.key;
		}
	},
	mounted() {
		swipe(this.$refs.container, this.$props.cardData.id);

		events().emitter.on('choice', (payload) => {
			this.$data.posClass = payload;
		});

		events().emitter.on('pushCard', (payload) => {
			if (payload.id === this.$props.cardData.id) {
				const card = this.$refs.container;

				if (payload.save) {
					if (payload.list === 'apply') {
						card.style.left = '600px';
						card.style.top = '-165px';
						card.style.transform = 'rotate(-4deg)';

						events().emitter.emit('setCard', {
							'save': true,
							'id': payload.id
						});
					}

					if (payload.list === 'saved') {
						card.style.left = '0px';
						card.style.top = '-600px';
						card.style.transform = 'rotate(0deg)';

						events().emitter.emit('setCard', {
							'save': true,
							'id': payload.id
						});
					}
				} else {
					card.style.left = '-600px';
					card.style.top = '-165px';
					card.style.transform = 'rotate(-4deg)';

					events().emitter.emit('setCard', {
						'save': false,
						'id': payload.id
					});
				}
			}
		});
	},
	methods: {
		apply() {
			const card = this.$refs.container;
			card.style.left = '600px';
			card.style.top = '-165px';
			card.style.transform = 'rotate(-4deg)';

			events().emitter.emit('setCard', {
				'save': true,
				'list': 'apply',
				'id': this.cardData.id
			});
		},
		discard() {
			const card = this.$refs.container;
			card.style.left = '-600px';
			card.style.top = '-165px';
			card.style.transform = 'rotate(-4deg)';

			events().emitter.emit('setCard', {
				'save': false,
				'id': this.cardData.id
			});
		},
		save() {
			const card = this.$refs.container;
			card.style.left = '0px';
			card.style.top = '-600px';
			card.style.transform = 'rotate(0deg)';

			events().emitter.emit('setCard', {
				'save': true,
				'list': 'saved',
				'id': this.cardData.id
			});
		},
		showInfo() {
			store.commit('setActiveJobCard', this.cardData);
		},
		closeModal() {
		},
		displayCard(status, list) {
			let show = true;

			if (list == 'jobs') {
				if (status == 'completed') {
					show = false;
				}
			}

			return show;
		},
		displaySaveBtn(list) {
			let show = true;

			if (list == 'matches') {
				show = false;
			}
		},
		displayDiscardBtn(list) {
			let show = true;

			if (list == 'matches') {
				show = false;
			}
		},
		displayApplyBtn(list) {
			let show = true;

			if (list == 'matches') {
				show = false;
			}
		}
	}
}
</script>

<style scoped>
.info-enter-active,
.info-leave-active {
	transition: opacity .3s ease .3s;
}

.info-enter-from,
.info-leave-to {
	opacity: 0;
}


.content {
	background: var(--color-black);
	width: 100%;
	height: 100%;
	overflow: hidden;
	transition: all .3s ease;
}

@media (min-width: 1024px) {
	.content {
		background-color: transparent;
	}
}

.card {
	display: block;
	width: calc(100% - var(--padding) - var(--padding));
	height: calc(100% - var(--padding) - var(--padding));
	margin: var(--padding) 0 0 var(--padding);
	padding: var(--padding);

	background-color: var(--color-black);
	color: var(--color-white);

	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	z-index: 1;

	border-radius: var(--radius);

	transform: scale(0.8);
	transition: all .3s ease;
}

.open-job-desktop {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: transparent;
	appearance: none;
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
	z-index: 1000;
	cursor: pointer;
}

@media (min-width: 1024px) {
	.card {
		position: relative;
		width: 33.33%;
		height: auto;
		margin: 0;

		transform: scale(1);

		background-color: transparent;

		left: auto !important;
		top: auto !important;
		transform: none !important;
	}

	.open-job-desktop {
		display: block;
	}
}

.info-content {
	position: fixed;
	left: var(--padding);
	top: 0;
	width: 100vw;
	padding: calc(var(--padding) * 2);
}

.card.show-info {
	background-color: var(--color-white);
	width: calc(100% + var(--padding) + var(--padding) + var(--padding) + var(--padding));
	left: calc(-2 * var(--padding)) !important;
	top: calc(-1.5 * var(--padding)) !important;
	height: 100vh;
}

.card.show-info .content {
	background-color: var(--color-white);
}

.card.show-info .card__inner {
	opacity: 0;
	transition: opacity .3s ease;
}

@media (min-width: 1024px) {
	.card.show-info .card__inner {
		opacity: 1;
	}
}

.card.in-front {
	transform:  scale(1);
}

.card__inner {
	width: 100%;
	height: 100%;
	background-color: var(--color-black);
	color:  var(--color-white);
	overflow: hidden;

	opacity: 0;
	transition: opacity .5s ease;	
}

@media (min-width: 1024px) {
	.card__inner {
		display: flex;
		flex-wrap: wrap;

		opacity: 1;
		padding: var(--padding);

		border-radius: var(--radius);
	}
}

.in-front .card__inner {
	opacity: 1;
}

.card__title {
	position: absolute;
	bottom: 90px;
	z-index: 100;
	width: 100%;
	margin: 0;
	color: var(--color-white);

	font-size: 28px;
	font-weight: bold;
}

@media (min-width: 1024px) {
	.card__title {
		position: static;
		order: 1;
	}
}

.card__details {
	width: 100%;
	margin-top: auto;
}

.card__details p {
	margin: 0;
	font-size: 18px;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	.card__details {
		order: 3;
		text-align: right;
	}
}

.card__image {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 120px);
	object-fit: contain;
	object-position: center;
	mix-blend-mode: screen;
}

@media (min-width: 1024px) {
	.card__image {
		position: static;
		display: block;
		max-width: 90%;
		height: 16vw;
		margin: 0 auto;
		order: 2;
	}
}

.card__image--no-image {
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	text-align: center;
	align-items: center;
}

.card__image--no-image p {
	width: 100%;
	font-size: 3vw;
}

.card.not-moving {
	transition: all 0s ease;
}

.path {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
}



.actions {
	display: flex;
	flex-wrap:  wrap;
	justify-content: center;
	margin-top: 40px;
	justify-content: space-around;

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	height: 75px;
}

@media (min-width: 1024px) {
	.actions {
		display: none;
		padding: 0 var(--padding) var(--padding);
		bottom: var(--padding);

	}
}

.actions button,
.actions .empty {
	display: block;
	border-radius: 50%;
	width: 82px;
	height: 82px;
	margin-top: -8px;
	background-color: var(--color-grey);

	transition: opacity .3s ease, transform .3s ease;
	transform:  scale(0.75);
}

.actions .empty {
	opacity: 0;
	cursor: default;
	pointer-events: none;
}

@media (min-width: 1024px) {
	.actions button {
		cursor: pointer;
	}
}

.actions button[disabled] {
	opacity: 0;
}

@media (min-width: 1024px) {
	.actions button[disabled] {
		cursor: default;
	}
}

.actions .save {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27.941' height='22.385' viewBox='0 0 27.941 22.385'%3E%3Cg data-name='Arrow Left'%3E%3Cpath data-name='Path 10' d='m22.169 8.5-1.455-1.455-5.506 5.506V.5H13.13v12.052L7.624 7.045 6.169 8.5l8 8Z' stroke='%23000'/%3E%3C/g%3E%3Cpath data-name='Path 232' d='M1.5 7.905v12.98h24.941V7.905' fill='none' stroke='%23000' stroke-width='3'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 54%;
	background-position: 50% 45%;
}

.actions .discard {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.138' height='21.138' viewBox='0 0 21.138 21.138'%3E%3Cg data-name='Component 2'%3E%3Cpath data-name='Rectangle 1445' fill='none' d='M.707.707h20v20h-20z'/%3E%3Cpath data-name='Union 3' d='M10.568 12.362 2.5 20.43.707 18.637l8.068-8.069L.707 2.5 2.5.707l8.068 8.068L18.637.707 20.431 2.5l-8.069 8.068 8.069 8.068-1.794 1.794Z' stroke='%23000'/%3E%3C/g%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 31%;
	background-position: 50% 50%;
}

.actions .apply {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32.641' height='22.511' viewBox='0 0 32.641 22.511'%3E%3Cpath d='M12.944 22.511 0 9.567l2.626-2.626 10.318 10.13L30.015 0l2.626 2.626Z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 50%;
	background-position: 50% 56%;
}

.actions .info {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='53' viewBox='0 0 15 53'%3E%3Ctext font-size='40' font-family='Degular-Medium, Degular' font-weight='500'%3E%3Ctspan x='0' y='41'%3Ei%3C/tspan%3E%3C/text%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 30%;
	background-position: 57% 50%;
}

.actions .save,
.actions .info {
	margin-top: 14px;
	width: 54px;
	height: 54px;

	background-color: var(--theme);
}

.actions.discard .discard,
.actions.save .save,
.actions.apply .apply {
	transform:  scale(0.95);
}
</style>