<template>
	<IonPage>
		<IonContent scroll-y="false" class="no-scroll">
			<div class="page-container">
				<div class="login" data-theme="theme-2">
					<transition name="fade-up">
						<div class="wrapper">

							<transition name="fade-up-form">
								<div class="login-screen-element" v-if="error">
									<div class="login-form">
										<h2>{{error}}</h2>

										<btn text="Login" modifier="block" @click="goToLoginPage"></btn>
									</div>
								</div>
							</transition>

							<transition name="fade-up-form">
								<div class="login-screen-element" v-if="wrongSite">
									<div class="login-form">
										<h2>{{wrongSite}}</h2>

										<btn text="Login" modifier="block" @click="goToOtherSite"></btn>
									</div>
								</div>
							</transition>

						</div>
					</transition>
				</div>
			</div>
		</IonContent>
	</IonPage>
</template>

<script>
import router from '../router/index';
import { store } from "../store/store";
import { mapState } from 'vuex';
import { defineComponent } from 'vue';
import useFirebaseAuth from '../hooks/firebase-auth';

import btn from '../components/button.vue';
import {
	IonPage,
	IonContent
} from '@ionic/vue';

import {
	getDoc
} from 'firebase/firestore';


const {
	validateEmailOnlyLogin,
	dbGetUser,
	dbCreateUser
} = useFirebaseAuth();

export default  {
	name: 'EmailLogin',
	components: {
		btn,
		IonPage,
		IonContent,
	},
	data: () => {
		return {
			error: null,
			wrongSite: null,
			response: null
		}
	},
	computed: {
		...mapState([
			'userType'
		]),
	},
	watch: {
    },
	methods: {
		async validate() {
			const response = await validateEmailOnlyLogin();

			if (response) {
				const userInfo = await dbGetUser(response.user.uid);

				console.log('userInfo in login: ', response);

				if (userInfo.length > 0) {
					if (userInfo[0].data().userType != this.userType) {
						this.wrongSite = 'Du er logget ind det forkerte sted — tryk på linket nedenfor og log ind igen';
					} else {
						this.$store.commit('setUser', response.user);
						this.$router.push({ path: '/jobs/jobs' });
					}
				} else {
					this.$store.commit('setUser', response.user);
					this.createUserInDb(response.user.uid, this.userType);
				}
			} else {
				this.error = 'Noget gik galt — prøv igen';
			}
		},
		goToLoginPage() {
			this.$router.push({ path: '/' });
		},
		goToOtherSite() {
			window.location = 'https://login.aim-jobs.com';
		},
		createUserInDb(userId, type) {
			dbCreateUser(userId, type)
				.then((result) => {
					console.log('Result: ', result);
					getDoc(result)
						.then((docSnap) => {
							console.log('created new user: ', docSnap.data());
							this.$store.commit('setUserData', docSnap.data());
							this.$router.push({ path: '/jobs/jobs' });
						})
				})
		},
		async setNewlyCreatedUserData() {

		}
	},
	created() {
		this.validate();
	}
}
</script>
<style scoped>
.login {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	text-align: center;
	margin-top: -30px;
}

h2,
.terms {
	padding-bottom: 0;
	padding-top: 40px;
}

h2 {
	padding-bottom: 40px;
}

.btn {
	margin-top: 30px;
}

.login-form {
	background-color: var(--color-white);
	border-radius: var(--radius);
	padding: var(--padding);
	margin-bottom: 40px;
}

.login-screen-element {
	position: absolute;
	top: 0;
	left: 20px;
	right: 20px;
	transform: translateY(-50%);
}

.page-container {
	height: 100%;
}

.fade-up-form-enter-active,
.fade-up-form-leave-active {
	transition: opacity .3s ease, top .3s ease;
}

.fade-up-form-enter-from,
.fade-up-form-leave-to {
	opacity: 0;
	top: 15px;
}
</style>