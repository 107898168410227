<template>
	<ion-app>
		<div class="page" :class="userType">
			<div v-if="userType == 'employer' && userData">
				<bottomMenu :type="userType" />
			</div>
			
			<div v-if="userType == 'user'">
				<transition name="show-menu">
					<bottomMenu v-if="user" :type="userType" />
				</transition>
			</div>

			<ion-router-outlet :key="$route.name" />
			<transition name="match">
				<div class="match" v-if="showMatch">
					<svg @click="goToMatch" xmlns="http://www.w3.org/2000/svg" width="218" height="344" viewBox="0 0 218 344"><g data-name="Group 765"><text data-name="It’s a Match!" transform="translate(109 75)" fill="#fff" font-size="73" font-family="Degular-Semibold, Degular" font-weight="600"><tspan x="-68.766" y="0">It’s a</tspan><tspan font-family="Degular-SemiboldItalic, Degular" font-style="italic"><tspan x="-108.88" y="55">Match!</tspan></tspan></text></g><rect data-name="Rectangle 1523" width="206" height="58" rx="29" transform="translate(10 286)" fill="#fff"/><rect data-name="Rectangle 1524" width="206" height="58" rx="29" transform="translate(10 198)" fill="#fff"/><text data-name="Answer Q&amp;A" transform="translate(55 209)" font-size="22" font-family="Degular-Light, Degular" font-weight="300"><tspan x="0" y="23">Answer Q&amp;A</tspan></text><text data-name="Keep looking" transform="translate(56 298)" font-size="22" font-family="Degular-Light, Degular" font-weight="300"><tspan x="0" y="23">Keep looking</tspan></text></svg>
				</div>
			</transition>
		</div>
	</ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import events from '../src/events';
import bottomMenu from './components/bottom-menu.vue';
import { useRouter } from 'vue-router';
import { mapState } from 'vuex';

import useFirebaseAuth from './hooks/firebase-auth';
const {
	logout,
	dbGet,
	isLoggedIn,
	authCheck,
	dbGetUser,
	dbCreateUser,
	setUserData
} = useFirebaseAuth();

const debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}

export default defineComponent({
	name: 'App',
	computed: mapState([
		'user',
		'userType',
		'authCheckLogin',
		'pageTitle',
		'userData'
	]),
	components: {
		IonApp,
		IonRouterOutlet,
		bottomMenu
	},
	watch: {
		user: {
			handler: function(user) {
				console.log('new user', user);
				if (!user) {
					this.$router.push({ path: '/' });
					console.log('show login!');
					events().emitter.emit('showLogin', true);
				} else {
					if (user.uid) {
						// this.checkUser(user.uid);
						this.setUsersData(user.uid);
					}
				}
			},
			deep: true
		},
		authCheckLogin: {
			handler: function(check) {
				if (check) {
				}
			},
			deep: true
		},
		$router: {
			handler: function(route) {
				this.$store.commit('setPageTitle', this.$route.name);
			},
			deep: true
		}
	},
	data: () => {
		return {
			showMatch: null,
			pageHeaderTitle: ''
		}
	},
	created() {
		authCheck();

		events().emitter.on('match', (payload) => {
			// this.showMatch = payload;
		});

		if (this.$router.name) {
			this.$store.commit('setPageTitle', this.$route.name);
		} else {
			this.$store.commit('setPageTitle', null);
		}

		window.addEventListener('resize', debounce(() => {
			this.$store.commit('touchChange', true);
		}, 300));
	},
	setup() {
		const router = useRouter();
		return { router };
	},
	methods: {
		goToMatch() {
			this.showMatch = false;
			this.$store.commit('login', true);
			this.$router.push({ path: '/jobs/matches' });
		},
		async doLogout() {
			const status = await logout();
			this.$store.commit('setUser', null);
			this.$store.commit('setUserData', null);
		},
		checkUser(userId) {
			dbGetUser(userId)
				.then((result) => {
					if (result.length == 0) {
						this.createUserInDb(userId, this.userType);
					}
				})
		},
		setUsersData(userId) {
			setUserData(userId)
				.then((result) => {
					console.log('setUserData: ', result);
				});
		},
		createUserInDb(userId, type) {
			dbCreateUser(userId, type)
				.then((result) => {
				})
		}
	}
});
</script>
<style scoped>
.show-menu-enter-active,
.show-menu-leave-active {
	transition: opacity .4s ease .1s, transform .4s ease .1s;
}

.show-menu-enter-from,
.show-menu-leave-to {
	opacity: 0;
	transform: translateY(100%) !important;
}

.slide-menu-enter-active,
.slide-menu-leave-active {
	transition: opacity .25s ease, transform .25s ease;
}

.slide-menu-enter-from,
.slide-menu-leave-to {
	transform: translateY(-200px) !important;
}

@media (min-width: 1024px) {
	.show-menu-enter-from,
	.show-menu-leave-to {
		opacity: 1;
		transform: translateY(-100%) !important;
	}
}


.match-enter-active,
.match-leave-active {
	transition: opacity .3s ease;
}

.match-enter-from,
.match-leave-to {
	opacity: 0;
}

.match {
	position: fixed;
	z-index: 999999999999;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: var(--color-black);
}

.match svg {
	position: absolute;
	top: 44%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.logout-button {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9999;
}
</style>
<style>
@font-face {
    font-family: 'Degular';
    src: url('/assets/fonts/DegularText-Regular.woff2') format('woff2'),
        url('/assets/fonts/DegularText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Degular';
    src: url('/assets/fonts/Degular-Semibold.woff2') format('woff2'),
        url('/assets/fonts/Degular-Semibold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@media (min-width: 1024px) {
	/**::-webkit-scrollbar {
		width: .7em;
	}
	 
	*::-webkit-scrollbar-track {
		background-color: var(--color-grey);
	}
	 
	*::-webkit-scrollbar-thumb {
		background-color: var(--color-black);
		border-radius: .5em;
		border: 2px solid var(--color-grey);
	}*/
}

html,
body,
#root {
  height: 100vh;
  width: 100%;
}

* {
	overscroll-behavior: none;
}

body {
  overflow: hidden;
}

.fade-up-enter-active,
.fade-up-leave-active {
	transition: opacity .5s ease, transform .5s ease;
}


.fade-up-enter-from,
.fade-up-leave-to {
	opacity: 0;
	transform: translateY(20px);
}

.page.employer {
	height: 100%;
}

.page.employer .ion-page,
.page.employer .hydrated {
	position: static;
	height: 100%;
	display: block;
	background-color: var(--color-white);
}

ion-app {
	height: 101%;
}

:root,
ion-content,
ion-router-outlet {
	--color-brand: #D7CBF4;
	--background: #E8E8E8;
	--theme: #AE99DC;
	--color-white: #FFFFFF;
	--color-black: #0B0B0B;
	--color-grey: #C7C7C7;
	--color-light-grey: #F2F2F2;
	--color-dark-grey: #707070;
	--color-text: #000000;
	--color: #0B0B0B;
	--color-link: #0B0B0B;
	--color-text-light: #FFFFFF;
	--radius: 22px;
	--radius-small: 16px;
	--padding: 18px;
	--padding-large: 30px;
	--padding-inner: 14px;
	--keyboard-offset: 0 !important;
	--font-family: 'Degular', sans-serif;
	--ion-font-family: var(--font-family);
}

[data-theme='theme-1'] {
	--theme: #EEE156;
	--color-text: #000000;
	--color-text-light: #FFFFFF;
	--color: #0B0B0B;
}

[data-theme='theme-2'] {
	--theme: #EAB2E3;
	--color-text: #F7F8F8;
	--color-link: #828fff;
	--color: #0B0B0B;
}

[data-theme='theme-3'] {
	--theme: #EA8B2C;
	--color-text: #F7F8F8;
	--color-link: #828fff;
	--color: #0B0B0B;
}

html,
body,
#root {
	background-color: var(--background) !important;
	color: var(--color-text);
	font-family: 'Degular', sans-serif;
	line-height: 1.35;
}

* {
	padding: 0;
	margin: 0;
	border: none;
	outline: none;
	box-sizing: border-box;
}

a {
	text-decoration: underline;
}

h1 {
	/*margin-top: 40px;*/
	margin-bottom: var(--padding);
	font-size: 40px;
}

h1,
h2,
h3,
h4
h5,
h6 {
	font-weight: bold;
}

.small-text {
	font-size: 14px;
	text-transform: uppercase;
}

ion-content{
	--padding-top: 0px;
	--background: var(--background);
	/*padding-top: var(--padding);*/
}

.inner-scroll {
	padding-top: 0;
}

.wrapper {
	width: 100%;
	padding-left: var(--padding);
	padding-right: var(--padding);

	position: relative;
}

@media (min-width: 1024px) {
	.wrapper {
		padding-left: var(--padding-large);
		padding-right: var(--padding-large);
	}
}

.page-container {
	padding-top: 60px;
	height: 100vh;
	overflow: auto;
}

.page.employer .page-container {
	padding-top: 0;
	height: calc(100vh - 74px);
	margin-top: 74px;

	background-color: var(--color-light-grey);
}

.plt-mobileweb .page-container {
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	height: -webkit-fill-available;
}

.page-container[data-theme] {
	background-color: var(--theme);
}


body #app div.vue-tags-input {
	max-width: 100%;
	margin-top: 10px;
}

body #app div.vue-tags-input .ti-input {
	padding: 0;
	border: none;
}

.vue-tags-input .ti-input .ti-new-tag-input-wrapper {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
}

.vue-tags-input .ti-input .ti-new-tag-input-wrapper input {
	display: block;
	width: 100%;
	padding: 17px 20px;
	appearance: none;
	border-radius: 15px;
	background-color: var(--color-light-grey);
	color: var(--color-black);
	font-size: 16px;
	line-height: 1.3;
	margin-top: 6px;
}

body #app div.vue-tags-input .ti-input .ti-tags .ti-tag {
	background-color: var(--color-black);
	color: var(--color-white);
	border-radius: 15px;
	padding: 5px 8px 6px 10px;
}

body #app div.vue-tags-input .ti-input .ti-tags .ti-tag .ti-actions {
	margin-left: 6px;
}

.page.user .ion-page {
	background-color: white;
}




.footer {
	 padding: var(--padding-large) 40px;
	 width: 100vw;
	 margin-left: calc(var(--padding-large) * -1);
	 background-color: white;
	 display: flex;
	 flex-direction: row;
}
 @media (max-width: 1024px) {
	 .footer {
		 flex-wrap: wrap;
		 padding: 40px 20px 17px;
	}
}
 .footer__item {
	 min-width: 180px;
	 padding-right: 40px;
}
 @media (max-width: 1024px) {
	 .footer__item {
		 min-width: 0;
		 padding-right: 0;
		 width: 50%;
		 order: 2;
	}
	 .footer__item:nth-child(2) {
		 width: 33%;
		 padding-left: 20px;
	}
}
 .footer__item svg {
	 width: auto;
	 height: auto;
	 max-height: 96px;
}
 @media (max-width: 1024px) {
	 .footer__item svg {
		 width: 40vw;
		 margin-bottom: -10px;
		 margin-left: -5%;
	}
	 .footer__item svg .st0 {
		 display: none;
	}
}
 @media (max-width: 1024px) {
	 .footer__item svg {
		 max-width: 190px;
	}
}
 .footer__item:last-child {
	 align-self: flex-end;
	 margin-left: auto;
	 padding-right: 0;
}
 @media (max-width: 1024px) {
	 .footer__item:last-child {
		 width: 100%;
		 order: 1;
	}
}
 .footer h2 {
	 margin-bottom: 20px;
}
 .footer p {
	 margin: 0;
	 font-size: 18px;
}
 .footer p a {
	 text-decoration: none;
	 color: var(--black);
}
 .footer p a:hover {
	 text-decoration: underline;
}
 
</style>