<template>
	<IonPage>
		<IonContent scroll-y="false" class="no-scroll">
			<div class="page-container">
				<transition name="fade-up">
					<div class="login" data-theme="theme-2" v-if="showPage">
						<div class="wrapper">

							<transition name="fade-up-form">
								<div class="login-screen-element" v-if="form == 'create'">
									<div class="login-screen-element-inner">
										<div class="login-form">
											<h2>Create an Account</h2>
											<inputField placeholder="Email" type="email" v-model="createEmail"></inputField>
											<inputField placeholder="Password" type="password" v-model="createPassword"></inputField>

											<div class="terms">
												<p>By creating an account you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a></p>
											</div>
										</div>

										<btn text="Create user" modifier="block" @click="doCreateUser"></btn>

										<p v-if="form == 'create'">If you already have an account <a @click="form = 'login'">login here</a></p>
									</div>
								</div>
							</transition>

							<transition name="fade-up-form">
								<div class="login-screen-element" v-if="form == 'login'">
									<div class="login-screen-element-inner">
										<div class="login-form">
											<h2>Login</h2>
											<inputField placeholder="Email" type="email" v-model="loginEmail"></inputField>
										</div>

										<btn @click="doEmailOnlyLogin" text="Login" modifier="block" :isDisabled="loginDisabled"></btn>

										<!-- <p v-if="form == 'login'">If you don't have an account <a @click="form = 'create'">register here</a></p> -->
									</div>
								</div>
							</transition>

							<transition name="fade-up-form">
								<div class="login-screen-element" v-if="form == 'emailSent'">
									<div class="login-screen-element-inner">
										<div class="login-form">
											<h2>Please check your email for login link</h2>
										</div>
									</div>
								</div>
							</transition>

							<transition name="fade-up-form">
								<div class="login-screen-element" v-if="form == 'welcome'">
									<div class="login-screen-element-inner">
										<div class="login-form">
											<h2>Welcom</h2>
											<p>Please check your email for validation link or <a @click="form = 'login'">login here</a></p>
										</div>

										<btn text="Continue" modifier="block" @click="form = 'login'"></btn>
									</div>
								</div>
							</transition>

						</div>
					</div>
				</transition>
			</div>
		</IonContent>
	</IonPage>
</template>

<script>
import inputField from '../components/input.vue';
import btn from '../components/button.vue';
import router from '../router/index';
import { store } from "../store/store";
import { mapState } from 'vuex';
import { defineComponent } from 'vue';
import events from '../events';
// import gAuthPlugin from 'vue3-google-oauth2';
import useFirebaseAuth from '../hooks/firebase-auth';
import {
	IonPage,
	IonContent
} from '@ionic/vue';

const {
	authCheck,
	login,
	logout,
	createUser,
	emailOnlyLogin,
	dbGetUser,
	dbCreateUser,
} = useFirebaseAuth();

export default  {
	name: 'Login',
	components: {
		inputField,
		btn,
		IonPage,
		IonContent,
	},
	data: () => {
		return {
			showPage: false,
			form: 'login',
			loginEmail: '',
			loginPassword: '',
			createEmail: '',
			createPassword: '',
			IsSignedIn: null,
			thisUser: null
		}
	},
	watch: {
		user: {
			handler: function(user) {
				if (user) {
					this.$router.push({ path: '/jobs/jobs' });
				} else {
					setTimeout(() => {
						this.showPage = true;
					}, 100);
				}
			},
			deep: true
		},
		authCheckLogin: {
			handler: function(check) {
				if (check) {
					setTimeout(() => {
						this.showPage = true;
					}, 300);
				}
			},
			deep: true
		}
	},
	computed: {
		...mapState([
			'user',
			'authCheckLogin',
			'userType'
		]),
		loginDisabled() {
			return !this.validateEmail(this.loginEmail);
		}
	},
	methods: {
		validateEmail(email) {
			return String(email)
				.toLowerCase()
				.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
		},
		async doLogin() {
			const thisUser = await login(this.loginEmail, this.loginPassword);
		},
		async doCreateUser() {
			const thisUser = await createUser(this.createEmail, this.createPassword);
		},
		async doEmailOnlyLogin() {
			const thisUser = await emailOnlyLogin(this.loginEmail);

			if (thisUser) {
				console.log('store set new user with this data: ', thisUser, this.userType);
				this.$store.commit('setUser', thisUser);
				this.$router.push({ path: '/jobs/jobs' });
			} else {
				this.form = 'emailSent';
			}
		},
		// checkUser(userId) {
		// 	// console.log(userId);
		// 	dbGetUser(userId)
		// 		.then((result) => {
		// 			// console.log('check: ', result);
		// 			if (result.length == 0) {
		// 				this.createUserInDb(userId, this.userType);
		// 			}
		// 		})
		// },
		// createUserInDb(userId, type) {
		// 	dbCreateUser(userId, type)
		// 		.then((result) => {
		// 			// console.log('create user: ', result);
		// 		})
		// }
	},
	created() {
	},
	mounted() {
		events().emitter.on('showLogin', (payload) => {
			console.log('show login! event');
			if (payload == true) {
				this.showPage = true;
			}
		});
	}
}
</script>
<style scoped>
.page-container {
	background-color: var(--color-light-grey);
}
.login {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	text-align: center;
	margin-top: -30px;
}

h2,
.terms {
	padding-bottom: 0;
	padding-top: 40px;
}

h2 {
	padding-bottom: 40px;
}

.btn {
	margin-bottom: 30px;
}

.login-form {
	background-color: var(--color-white);
	border-radius: var(--radius);
	padding: var(--padding);
	margin-bottom: 20px;
}

.login-screen-element {
	position: absolute;
	top: 0;
	left: 20px;
	right: 20px;
	transform: translateY(-50%);
}

.login-screen-element-inner {
	max-width: 440px;
	margin: 0 auto;
}

.page-container {
	height: 100%;
}

.fade-up-form-enter-active,
.fade-up-form-leave-active {
	transition: opacity .3s ease, top .3s ease;
}

.fade-up-form-enter-from,
.fade-up-form-leave-to {
	opacity: 0;
	top: 15px;
}
</style>