<template>
	<IonPage>
		<IonContent>
			<div class="page-container">
				<transition name="fade-up">
					<div class="saved" v-if="isActive">
						<div class="wrapper">
							<h1>Matches</h1>
						</div>
					</div>
				</transition>
			</div>
		</IonContent>
	</IonPage>
</template>

<script>
// import events from '../events';
// import { Storage } from '@ionic/storage';
// import router from '../router/index';
import { mapState } from 'vuex';

import {
    IonPage,
    IonContent
} from '@ionic/vue';

export default  {
	name: 'Matches',
	components: {
        IonPage,
        IonContent
	},
	computed: {
		...mapState([
			'user',
			'page'
		]),
		isActive() {
			return this.page == '/matches'
		}
	},
	data: () => {
		return {
			showPage: false
		}
	},
	created() {
		if (!this.user) {
			this.$router.push({ path: '/' });
		}
	},
	mounted() {
		setTimeout(() => {
			this.showPage = true;
		}, 500);
	},
	methods: {
		
	}
}
</script>
<style scoped>
</style>