<template>
	<div class="job">

		
		<div class="job-tile" v-if="type == 'job'">

			<div class="job-actions">
				<button @click="showApplicants" v-if="job.status == 'published' && job.applicants.length > 0">
					<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M48 208c-26.51 0-48 21.5-48 48s21.49 48 48 48 48-21.5 48-48-21.49-48-48-48zm0 160c-26.51 0-48 21.5-48 48s21.49 48 48 48 48-21.5 48-48-21.49-48-48-48zm0-320C21.49 48 0 69.49 0 96s21.49 48 48 48 48-21.5 48-48-21.49-48-48-48zm144 80h288c17.67 0 32-14.33 32-31.1S497.7 64 480 64H192c-17.7 0-32 14.33-32 31.1s14.3 32.9 32 32.9zm288 96H192c-17.7 0-32 14.3-32 32s14.33 32 32 32h288c17.67 0 32-14.33 32-32s-14.3-32-32-32zm0 160H192c-17.67 0-32 14.33-32 32s14.33 32 32 32h288c17.67 0 32-14.33 32-32s-14.3-32-32-32z"/></svg>

					<span class="tip" v-if="job.status == 'published'">
						{{job.applicants.length}}
					</span>
				</button>

				<button v-if="job.status == 'published' && job.applicants.length == 0" class="no-cursor">
					<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M48 208c-26.51 0-48 21.5-48 48s21.49 48 48 48 48-21.5 48-48-21.49-48-48-48zm0 160c-26.51 0-48 21.5-48 48s21.49 48 48 48 48-21.5 48-48-21.49-48-48-48zm0-320C21.49 48 0 69.49 0 96s21.49 48 48 48 48-21.5 48-48-21.49-48-48-48zm144 80h288c17.67 0 32-14.33 32-31.1S497.7 64 480 64H192c-17.7 0-32 14.33-32 31.1s14.3 32.9 32 32.9zm288 96H192c-17.7 0-32 14.3-32 32s14.33 32 32 32h288c17.67 0 32-14.33 32-32s-14.3-32-32-32zm0 160H192c-17.67 0-32 14.33-32 32s14.33 32 32 32h288c17.67 0 32-14.33 32-32s-14.3-32-32-32z"/></svg>

					<span class="tip" v-if="job.status == 'published'">
						{{job.applicants.length}}
					</span>
				</button>

				<button class="archive" @click="archiveJob" v-if="job.status != 'published'">
					<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1"><path d="M356.65 450H171.47a41 41 0 0 1-40.9-40.9V120.66a15 15 0 0 1 15-15h237a15 15 0 0 1 15 15V409.1a41 41 0 0 1-40.92 40.9ZM160.57 135.66V409.1a10.91 10.91 0 0 0 10.9 10.9h185.18a10.91 10.91 0 0 0 10.91-10.9V135.66Z"/><path d="M327.06 135.66h-126a15 15 0 0 1-15-15V93.4a44.79 44.79 0 0 1 44.74-44.73h66.52a44.79 44.79 0 0 1 44.74 44.73v27.26a15 15 0 0 1-15 15Zm-111-30h96V93.4a14.75 14.75 0 0 0-14.74-14.73H230.8a14.75 14.75 0 0 0-14.73 14.73ZM264.06 392.58a15 15 0 0 1-15-15V178.09a15 15 0 1 1 30 0v199.49a15 15 0 0 1-15 15ZM209.9 392.58a15 15 0 0 1-15-15V178.09a15 15 0 0 1 30 0v199.49a15 15 0 0 1-15 15ZM318.23 392.58a15 15 0 0 1-15-15V178.09a15 15 0 0 1 30 0v199.49a15 15 0 0 1-15 15Z"/><path d="M405.81 135.66H122.32a15 15 0 0 1 0-30h283.49a15 15 0 0 1 0 30Z"/></g></svg>
				</button>

				<button class="" @click="updateJob" v-if="job.status != 'published'">
					<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1"><path d="M403.67 187.94a68 68 0 0 1-9.16-.63c-17.89-2.4-35.5-11.48-49.57-25.55-14.07-14.08-23.15-31.68-25.56-49.58-2.57-19.12 2.93-37.07 15.11-49.25s30.13-17.69 49.25-15.11c17.9 2.41 35.51 11.48 49.58 25.56s23.15 31.68 25.56 49.57c2.57 19.13-2.94 37.08-15.11 49.26-10.21 10.2-24.48 15.73-40.1 15.73Zm-37.52-47.4c9.31 9.32 21.11 15.53 32.37 17 9.91 1.34 18.45-1 24-6.59s7.93-14.12 6.59-24c-1.51-11.26-7.72-23.06-17-32.37C394 76.47 368.16 71.69 355.7 84.14s-7.67 38.29 10.45 56.4ZM189.36 402.24a68 68 0 0 1-9.15-.62c-17.9-2.41-35.51-11.48-49.58-25.56s-23.15-31.68-25.56-49.57c-2.57-19.13 2.94-37.08 15.11-49.26a15 15 0 0 1 21.22 21.22c-5.59 5.58-7.93 14.12-6.6 24 1.52 11.26 7.73 23.06 17 32.37 18.2 18.18 43.99 22.93 56.45 10.48a15 15 0 1 1 21.21 21.21c-10.21 10.21-24.46 15.73-40.1 15.73Z"/><path d="M71.69 450a15 15 0 0 1-14.64-18.25c.26-1.17 6.46-29 15.07-59.7 16.16-57.6 25.91-72.67 32.6-79.36L334.49 62.93a15 15 0 1 1 21.21 21.21L125.94 313.91c-.91.9-9.27 10.43-24.94 66.25-3.51 12.51-6.65 24.69-9.1 34.64 10-2.47 22.21-5.61 34.75-9.14 55.72-15.64 65.24-24 66.14-24.9L422.55 151a15 15 0 0 1 21.22 21.21L214 402c-6.69 6.69-21.75 16.44-79.36 32.61-30.65 8.6-58.53 14.8-59.7 15.06a15 15 0 0 1-3.25.33Z"/><path d="M391.63 135.07a15 15 0 0 1-10.61-4.4l-5-5a15 15 0 0 1 21.21-21.21l5 5a15 15 0 0 1-10.6 25.61Z"/></g></svg>
				</button>
			</div>

			<transition name="fade-up">
				<div v-if="isArchiving" class="archive-this">
					<p>Er du sikker på at du vil slette jobopslaget?</p>

					<div class="archive-this__buttons">
						<btn text="Nej" modifier="inverted" @click="dontArchive"></btn>
						<btn text="Ja" modifier="inverted" @click="archiveThisJob"></btn>
					</div>
				</div>
			</transition>

			<div class="job-inner">
				<div class="job-content">
					<h2>{{job.title}}</h2>
					<p><strong>Type:</strong> {{job.type}}</p>
					<p><strong>Lokation:</strong> {{job.location}}</p>
					<br>
					<p><strong>Status:</strong> {{getStatus(job.status)}}</p>
				</div>
			</div>
		</div>

		
		<Modal v-show="isEditModalVisible" @close="closeModal" v-if="type == 'job'" :modifierClass="'full'">
			<template v-slot:header>
			</template>

			<template v-slot:body>
				<transition name="fade-up">
					<div class="step" v-if="step == 1">
						<inputField label="Title" placeholder="Ex. grafisk designer, opvasker, barista" type="text" v-model="jobTitle"></inputField>
						<inputField label="Type" type="select" :options="['Fuldtid', 'Deltid', 'Freelance', 'Praktik', 'Studiejob']" v-model="jobType" :selectDefault="'Vælg'"></inputField>

						<inputField label="Lokation" :selectDefault="'Vælg postnummer og by'" type="select" :options="jobLocationOptions" v-model="jobLocation"></inputField>
					</div>
				</transition>

				<transition name="fade-up">
					<div class="step" v-if="step == 2">
						<inputField label="Opgavebeskrivelse" type="textarea" v-model="jobDescription"></inputField>
					</div>
				</transition>

				<transition name="fade-up">
					<div class="step" v-if="step == 3">
						<inputField label="Virksomhedsbeskrivelse" type="textarea" v-model="jobCompanyDescription"></inputField>
					</div>
				</transition>

				<transition name="fade-up">
					<div class="step" v-if="step == 4">
						<inputField label="Kandidatbeskrivelse" type="textarea" v-model="jobCandidateDescription"></inputField>
					</div>
				</transition>

			</template>

			<template v-slot:footer>
				<div class="job-modal-footer">
					<div class="job-modal-footer__item">
						<btn text="Udgiv" @click="updateJobPublish" :isDisabled="isStepValid" v-if="job.status != 'published'"></btn>
						<btn text="Gem kladde" @click="saveJobDraft" :isDisabled="isStepValid" v-if="job.status != 'published'"></btn>
						<btn text="Opdater" @click="updateJobPublish" :isDisabled="isStepValid" v-if="job.status == 'published'"></btn>
					</div>
					<div class="job-modal-footer__item">
						<ul class="step-count">
							<li v-for="(steps, index) in totalSteps" :class="{ 'active-step': step == index + 1 }" :key="index"></li>
						</ul>
					</div>
					<div class="job-modal-footer__item">
						<btn text="" :modifier="'back'" @click="prevStep" v-if="step > 1 || step == null"></btn>
						<btn text="Næste" @click="nextStep" :isDisabled="isStepValid" v-if="step != totalSteps"></btn>
					</div>
				</div>
			</template>
		</Modal>

		<Modal v-show="isApplicantsModalVisible" @close="closeModal" v-if="type == 'job'" :modifierClass="'full'">
			<template v-slot:header>
				<h1>{{job.title}}</h1>
			</template>

			<template v-slot:body>
				<div class="applicants-container">
					<p>{{job.applicants.length}} kandidater</p>
					<table class="applicants">
						<thead>
							<tr>
								<th><!--input type="checkbox" name=""--></th>
								<th>Kandidat</th>
								<th>Resultat</th>
								<th>Antal ratings</th>
							</tr>
						</thead>
						<tbody>

							<tr v-for="(applicant, index) in job.applicants" :key="index">
								<td><input type="checkbox" name=""></td>
								<td>{{applicant.userId}}</td>
								<td>
									<span class="rating-bar">
										<span :style="{ width: randPercentage() + '%' }"></span>
									</span>
								</td>
								<td>
									2/5
								</td>
							</tr>

						</tbody>
					</table>
				</div>

			</template>

			<template v-slot:actions>
				<btn text="Rate ansøgere" @click="startRating" :modifier="'inverted'"></btn>
				<btn text="Indkald til samtale" :isDisabled="true" :modifier="'inverted'"></btn>
			</template>

			<template v-slot:footer>

			</template>
		</Modal>


		<Modal v-show="isRatingModalVisible" @close="closeRatingModal" v-if="type == 'job'" :modifierClass="'full'">
			<template v-slot:header>
				<h1>{{job.title}}</h1>
			</template>

			<template v-slot:body>
				<div class="applicants-container">
					<!-- <div v-for="(applicant, index) in job.applicants" :key="index"> -->
					<div v-if="activeCandidate" class="single-applicant">
						<div style="text-align: center;">
							<p>Kandidat: {{activeCandidate.userId}}</p>
						</div>
						<div class="cv-item" v-if="activeCandidate.cv.length == 0">
							<p style="display: block; text-align: center;"><strong>Kandidaten har ikke udfyldt sit CV endnu</strong></p>
						</div>
						<div class="cv-item" v-for="(item, index) in activeCandidate.cv" :key="index">
							<p>
								<strong>{{item.title}}</strong>
								<span v-if="item.type == 'job'"> ({{item.company}})</span>
								<span v-if="item.type == 'education'"> ({{item.school}})</span>
							</p>
							<span class="small-text" v-if="item.end != 'present'">{{item.start}} — {{item.end}}</span>
							<span class="small-text" v-if="item.end == 'present'">{{item.start}} — nuværende</span>

							<ul v-if="item.tags" class="tags">
								<li v-for="(tag, index) in item.tags" :key="index">{{tag.text}}</li>
							</ul>
						</div>
					</div>
				</div>
			</template>

			<template v-slot:actions>
				<btn text="Fortryd" @click="previousRating" :modifier="'inverted'" :isDisabled="activeCandidateIndex == 0"></btn>
				<div class="rating-actions">
					<ul>
						<li @click="rateApplier(1)">
							<svg xmlns="http://www.w3.org/2000/svg" width="47" height="48" viewBox="0 0 47 48"><g data-name="Group 883" transform="translate(-1052 -1019)"><g data-name="Group 873" transform="rotate(180 549.5 533.505)"><rect data-name="Rectangle 1547" width="47" height="48" rx="23.5" transform="translate(0 .009)" fill="#ea8b2c"/></g><circle data-name="Ellipse 362" cx="3" cy="3" r="3" transform="translate(1066 1036)"/><circle data-name="Ellipse 367" cx="3" cy="3" r="3" transform="translate(1079 1036)"/><path data-name="Path 160" d="M1066.667 1053.235s7.637-10.25 17.887 0" fill="none" stroke="#000" stroke-width="3"/></g></svg>
						</li>
						<li @click="rateApplier(2)">
							<svg xmlns="http://www.w3.org/2000/svg" width="47" height="48" viewBox="0 0 47 48"><g data-name="Group 882" transform="translate(-994 -1019)"><g data-name="Group 874" transform="rotate(180 520.572 533.505)"><rect data-name="Rectangle 1547" width="47" height="48" rx="23.5" transform="translate(.144 .009)" fill="#ffc386"/></g><circle data-name="Ellipse 363" cx="3" cy="3" r="3" transform="translate(1008 1036)"/><circle data-name="Ellipse 372" cx="3" cy="3" r="3" transform="translate(1021 1036)"/><path data-name="Path 162" d="M1008.811 1052.004s9.311-3.669 17.887 0" fill="none" stroke="#000" stroke-width="3"/></g></svg>
						</li>
						<li @click="rateApplier(3)">
							<svg xmlns="http://www.w3.org/2000/svg" width="46" height="48" viewBox="0 0 46 48"><g data-name="Group 881" transform="translate(-937 -1019)"><g data-name="Group 875" transform="rotate(180 491.643 533.505)"><rect data-name="Rectangle 1547" width="46" height="48" rx="23" transform="translate(.287 .009)" fill="#f8eb5b"/></g><circle data-name="Ellipse 364" cx="3" cy="3" r="3" transform="translate(950 1036)"/><circle data-name="Ellipse 369" cx="3" cy="3" r="3" transform="translate(964 1036)"/><path data-name="Path 164" d="M950.954 1052.004h17.766" fill="none" stroke="#000" stroke-width="3"/></g></svg>
						</li>
						<li @click="rateApplier(4)">
							<svg xmlns="http://www.w3.org/2000/svg" width="46" height="48" viewBox="0 0 46 48"><g data-name="Group 878" transform="translate(-879 -1019)"><g data-name="Group 876" transform="rotate(180 462.715 533.505)"><rect data-name="Rectangle 1547" width="46" height="48" rx="23" transform="translate(.431 .009)" fill="#c3d175"/></g><circle data-name="Ellipse 365" cx="3" cy="3" r="3" transform="translate(892 1036)"/><circle data-name="Ellipse 370" cx="3" cy="3" r="3" transform="translate(906 1036)"/><path data-name="Path 163" d="M910.867 1050.373s-9.311 3.669-17.887 0" fill="none" stroke="#000" stroke-width="3"/></g></svg>
						</li>
						<li @click="rateApplier(5)">
							<svg xmlns="http://www.w3.org/2000/svg" width="47" height="48" viewBox="0 0 47 48"><g data-name="Group 880"><g data-name="Group 877"><path data-name="Path 165" d="M23.5 48A23.5 23.5 0 0 1 0 24.5v-1a23.5 23.5 0 0 1 47 0v1A23.5 23.5 0 0 1 23.5 48Z" fill="#75c363"/></g><g data-name="Group 879" transform="translate(-821 -1019)"><circle data-name="Ellipse 366" cx="3" cy="3" r="3" transform="translate(834 1036)"/><circle data-name="Ellipse 371" cx="3" cy="3" r="3" transform="translate(848 1036)"/><path data-name="Path 161" d="M853.009 1048.91s-7.637 10.25-17.887 0" fill="none" stroke="#000" stroke-width="3"/></g></g></svg>
						</li>
					</ul>
				</div>
				<div> </div>
			</template>
		</Modal>


		<div class="job-tile" v-if="type == 'add'">
			<div class="job-inner job-inner__dark" @click="addJob">
				<div class="job-content">
					<span class="round-btn">+</span>
					<p>Opret nyt<br>job</p>
				</div>
			</div>
		</div>

		<Modal v-show="isModalVisible" @close="closeModal" v-if="type != 'job'" :modifierClass="'full'">
			<template v-slot:header>
			</template>

			<template v-slot:body>

				<transition name="fade-up">
					<div class="step" v-if="step == 1">
						<inputField label="Title" placeholder="Ex. grafisk designer, opvasker, barista" type="text" v-model="jobTitle"></inputField>
						<inputField label="Type" type="select" :options="['Fuldtid', 'Deltid', 'Freelance', 'Praktik', 'Studiejob']" v-model="jobType" :selectDefault="'Vælg'"></inputField>

						<inputField label="Lokation" :selectDefault="'Vælg postnummer og by'" type="select" :options="jobLocationOptions" v-model="jobLocation"></inputField>
					</div>
				</transition>

				<transition name="fade-up">
					<div class="step" v-if="step == 2">
						<inputField label="Opgavebeskrivelse" type="textarea" v-model="jobDescription"></inputField>
					</div>
				</transition>

				<transition name="fade-up">
					<div class="step" v-if="step == 3">
						<inputField label="Virksomhedsbeskrivelse" type="textarea" v-model="jobCompanyDescription"></inputField>
					</div>
				</transition>

				<transition name="fade-up">
					<div class="step" v-if="step == 4">
						<inputField label="Kandidatbeskrivelse" type="textarea" v-model="jobCandidateDescription"></inputField>
					</div>
				</transition>

			</template>

			<template v-slot:footer>
				<div class="job-modal-footer">
					<div class="job-modal-footer__item">
					</div>
					<div class="job-modal-footer__item">
						<ul class="step-count">
							<li v-for="(steps, index) in totalSteps" :class="{ 'active-step': step == index + 1 }" :key="index"></li>
						</ul>
					</div>
					<div class="job-modal-footer__item">
						<btn text="" :modifier="'back'" @click="prevStep" v-if="step > 1 || step == null"></btn>
						<btn text="Næste" @click="nextStep" :isDisabled="isStepValid" v-if="step != totalSteps"></btn>
						<btn text="Gem kladde" @click="saveJobDraft" :isDisabled="isStepValid" v-if="step == totalSteps"></btn>
						<btn text="Udgiv" @click="createJob" :isDisabled="isStepValid" v-if="step == totalSteps"></btn>
					</div>
				</div>
			</template>
		</Modal>

	</div>
</template>

<script>
import useFirebaseAuth from '../../hooks/firebase-auth';
import { mapState } from 'vuex';

import Modal from '../../components/modal.vue';
import btn from '../../components/button.vue';
import inputField from '../../components/input.vue';

const {
	dbGet,
	dbAddJob,
	dbGetJobs,
	archiveJob,
	updateJobInfo
} = useFirebaseAuth();

export default {
	name: 'employerJob',
	components: {
		Modal,
		inputField,
		btn
	},
	props: {
		job: Object,
		type: String
	},
	computed: {
		...mapState([
			'user',
			'userData',
			'jobs',
			'zipCodes'
		]),
		isStepValid() {
			let valid = true;

			if (this.step == 1) {
				if (this.jobTitle.length == 0) {
					valid = false;
				}

				if (this.jobLocation.length == 0) {
					valid = false;
				}

				if (this.jobType.length == 0) {
					valid = false;
				}
			} else if (this.step == 2) {
				if (this.jobDescription.length == 0) {
					valid = false;
				}
			} else if (this.step == 3) {
				if (this.jobCompanyDescription.length == 0) {
					valid = false;
				}
			} else if (this.step == 4) {
				if (this.jobCandidateDescription.length == 0) {
					valid = false;
				}
			}

			return !valid;
		},
		activeCandidate() {
			console.log('log computed')
			return this.job.applicants[this.activeCandidateIndex]
		}
	},
	watch: {
		job: {
			handler: function (job) {
				this.setJobData();
			}
		}
	},
	data() {
		return {
			isArchiving: false,
			step: 1,
			totalSteps: 4,
			isModalVisible: false,
			isEditModalVisible: false,
			isApplicantsModalVisible: false,
			isRatingModalVisible: false,
			jobTitle: '',
			jobLocation: '',
			jobLocationOptions: null,
			jobType: '',
			jobDescription: '',
			jobCompanyDescription: '',
			jobCandidateDescription: '',
			activeCandidateIndex: 0
		};
	},
	methods: {
		randPercentage() {
			let rand = Math.floor(Math.random() * 100) + 1;
			
			if (rand < 20) {
				rand = 20;
			}

			console.log('rand: ', rand);

			return rand;
		},
		startRating() {
			this.isRatingModalVisible = true;
		},
		rateApplier(rating) {
			console.log('Rating: ', rating + ' Applicant: ? ', this);
			if (this.activeCandidateIndex + 1 == this.job.applicants.length) {
				console.log('is last')
				this.isRatingModalVisible = false;

				setTimeout(() => {
					this.activeCandidateIndex = 0;
				}, 300);
			} else {
				this.activeCandidateIndex++;
			}
		},
		previousRating() {
			this.activeCandidateIndex--;
		},
		getStatus(status) {
			let statusResponse = '';

			if (status == 'published') {
				statusResponse = 'Åbent for ansøgere';
			} else if (status == 'draft') {
				statusResponse = 'Kladde';
			} else if (!status) {
				statusResponse = 'Kladde';
			}

			return statusResponse;
		},
		archiveJob() {
			this.isArchiving = true;
		},
		dontArchive() {
			this.isArchiving = false;
		},
		archiveThisJob() {
			if (this.job) {
				archiveJob(this.job.id)
					.then((response) => {
						if (response) {
							dbGetJobs(this.user.uid);

							this.isArchiving = false;
						}
					});
			}
		},
		closeModal() {
			this.isModalVisible = false;
			this.isEditModalVisible = false;
			this.isApplicantsModalVisible = false;
		},
		closeRatingModal() {
			this.isRatingModalVisible = false;
		},
		showApplicants() {
			// if (this.job.applicants.length > 0) {
				this.isApplicantsModalVisible = true;
			// }
		},
		addJob() {
			this.isModalVisible = true;
		},
		updateJob() {
			this.setJobData();

			this.isEditModalVisible = true;
		},
		nextStep() {
			if (this.step == this.totalSteps) {

			} else {
				const activeStep = this.step;
				this.step = null;

				setTimeout(() => {
					this.step = activeStep + 1;
				}, 80);
			}
		},
		prevStep() {
			const activeStep = this.step;
			this.step = null;

			setTimeout(() => {
				this.step = activeStep - 1;
			}, 80);
		},
		updateJobPublish() {
			if (this.job) {
				const data = {
					'title': this.jobTitle,
					'location': this.jobLocation,
					'type': this.jobType,
					'description': this.jobDescription,
					'companyDescription': this.jobCompanyDescription,
					'candidateDescription': this.jobCandidateDescription,
					'employerId': this.user.uid,
					'status': 'published'
				};

				updateJobInfo(this.job.id, data)
					.then(() => {
						this.isEditModalVisible = false;
						dbGetJobs(this.user.uid);
						this.step = 1;
					});
			}
		},
		updateJobDraft() {
			if (this.job) {
				const data = {
					'title': this.jobTitle,
					'location': this.jobLocation,
					'type': this.jobType,
					'description': this.jobDescription,
					'companyDescription': this.jobCompanyDescription,
					'candidateDescription': this.jobCandidateDescription,
					'employerId': this.user.uid,
					'status': 'draft'
				}

				updateJobInfo(this.job.id, data)
					.then(() => {
						this.isEditModalVisible = false;
						dbGetJobs(this.user.uid);
						this.step = 1;
					});
			}
		},
		saveJobDraft() {
			dbAddJob({
				'title': this.jobTitle,
				'location': this.jobLocation,
				'type': this.jobType,
				'description': this.jobDescription,
				'companyName': this.userData.companyName,
				'companyDescription': this.jobCompanyDescription,
				'candidateDescription': this.jobCandidateDescription,
				'employerId': this.user.uid,
				'status': 'draft'
			})
			.then(() => {
				this.isModalVisible = false;
				this.isEditModalVisible = false;

				setTimeout(() => {
					this.step = 1;
				}, 350);

				if (this.type != 'job') {
					this.jobTitle = '';
					this.jobLocation = '';
					this.jobType = '';
					this.jobDescription = '';
					this.jobCompanyDescription = '';
					this.jobCandidateDescription = '';
				}
			});
		},
		createJob() {
			dbAddJob({
				'title': this.jobTitle,
				'location': this.jobLocation,
				'type': this.jobType,
				'description': this.jobDescription,
				'companyName': this.userData.companyName,
				'companyDescription': this.jobCompanyDescription,
				'candidateDescription': this.jobCandidateDescription,
				'employerId': this.user.uid,
				'status': 'published'
			})
			.then(() => {
				this.isModalVisible = false;
				this.isEditModalVisible = false;

				setTimeout(() => {
					this.step = 1;
				}, 350);

				if (this.type != 'job') {
					this.jobTitle = '';
					this.jobLocation = '';
					this.jobType = '';
					this.jobDescription = '';
					this.jobCompanyDescription = '';
					this.jobCandidateDescription = '';
				}
			});
		},
		setJobData() {
			if (this.type == 'job') {
				this.jobTitle = this.job.title;
				this.jobLocation = this.job.location;
				this.jobType = this.job.type;
				this.jobDescription = this.job.description;
				this.jobCompanyDescription = this.job.companyDescription;
				this.jobCandidateDescription = this.job.candidateDescription;
			}
		}
	},
	created() {
		if (this.jobTitle) {
			this.isArchiving = true;
			this.setJobData();
		}

		this.jobLocationOptions = this.zipCodes;
	}
}
</script>

<style>
input[type="checkbox"] {
	appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border: 1px solid currentColor;
  border-radius: 0;
  /*transform: translateY(-0.075em);*/

  display: grid;
  place-content: center;
}

input[type="checkbox"]:before {
  content: "";
  width: 22px;
  height: 22px;
  transform: scale(0);
  transition: 40ms transform ease-in-out;
  box-shadow: inset 2em 2em var(--color-black);
}

input[type="checkbox"]:checked:before {
  transform: scale(0.84);
}


.step .input textarea {
	min-height: 300px;
}
</style>
<style scoped>
.fade-up-enter-active,
.fade-up-leave-active {
	transition: opacity .25s ease, transform .25s ease;
}


.fade-up-enter-from,
.fade-up-leave-to {
	opacity: 0;
	transform: translateY(6px);
}

.step {
	/*background-color: red;*/
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 1100px;
	height: auto;
	overflow: auto;

	padding: 60px;
	margin: 0;

	border-radius: 20px;

	background-color: var(--color-brand);
}

.job {
	width: 33.33%;
	padding: 0 15px 30px;
}

.job-tile {
	position: relative;
	width: 100%;
	padding-top: 100%;
}

.job-inner {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
	padding: 20px;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;

	text-align: center;

	overflow: hidden;

	border-radius: 25px;
	background-color: var(--color-black);
	color:  var(--color-white);

	cursor: default;
}

/*.job-inner.inactive {
	cursor: default;
}*/

.job-content {
	width: 100%;
	height: auto;
}

.job-inner__dark {
	background-color: var(--color-black);

	color:  var(--color-white);

	cursor: pointer;
}

.job-inner__dark p {
	display: block;
	text-align: center;
	line-height: 1.1;
}

.round-btn {
	display: block;
	width: 60px;
	height: 60px;
	margin: 10px auto;
	background-color: var(--color-light-grey);
	color: var(--color-black);
	border-radius: 50%;
	text-align: center;
	font-size: 38px;
	line-height: 56px;
}

.applicants-container {
	padding: 60px;
	margin: 0;

	border-radius: 20px;

	background-color: var(--color-brand);
}

.applicants {
	width: 100%;
	text-align: left;
	padding: 0;
	margin: 10px 0 0;
	border-collapse: collapse;

	background-color: var(--color-white);

	border: 1px solid var(--color-dark-grey);
	border-bottom: 0;
}

.applicants tr td,
.applicants tr th {
	padding: 10px;
	border-bottom: 1px solid var(--color-dark-grey);
	border-right: 1px solid var(--color-dark-grey);
	vertical-align: middle;
}

.applicants tr td:nth-child(3),
.applicants tr th:nth-child(3) {
	width: 50%;
}

.applicants li {
	display: block;
	padding: 0;
	margin: 0 0 var(--padding);
	list-style-type: none;
}

.applicants li h3 {
	margin-bottom: var(--padding);
}

.applicants li:last-child {
	margin-bottom: 0;
}

.applicants .cv-item {
	margin: 0 0 var(--padding);
}

.single-applicant .cv-item {
	margin: 20px 0;
	border-radius: 14px;
	padding: 20px 30px;
	background-color: var(--color-white);
}

.single-applicant .cv-item p {
	font-size: 22px;
}

.single-applicant .small-text {
	font-size: 16px;
}

.applicants .cv-item:last-child {
	margin-bottom: 0;
}


.job-modal-footer {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
}

.job-modal-footer__item {
	width: 33.33%;
	text-align: center;
}

.job-modal-footer__item:first-child {
	text-align: left;
}

.job-modal-footer__item:last-child {
	text-align: right;
}

.job-modal-footer__item .btn {
	display: inline-block;
	margin-left: 20px;
}

.job-modal-footer__item:first-child .btn {
	margin-left: 0;
	margin-right: 20px;
}

.step-count {
	display: block;
	padding: 0;
	margin: 0;
	text-align: center;
	align-items: center;
}

.step-count li {
	display: inline-block;
	width: 8px;
	height: 8px;
	margin: 6px;

	background-color: var(--color-grey);
	border-radius: 50%;

	transition: background-color .3s ease;
}

.step-count li.active-step {
	background-color: var(--color-black);
}

table tr th,
table tr td {
	vertical-align: top;
	padding: 8px 0;
}

table tr th:last-child,
table tr td:last-child {
	text-align: right;
}

.job-actions button {
	appearance: none;
	display: inline-block;
	
	z-index: 5;
	background-color: transparent;
	color: var(--color-black);
	text-transform: uppercase;
	font-size: 16px;

	position: relative;

	margin-left: 10px;

	cursor: pointer;

	background-color: var(--color-grey);
	padding: 10px;
	border-radius: 50%;
}

.job-actions button.no-cursor {
	cursor: default;
}

.job-actions button.inactive {
	cursor: default;
	opacity: 0;
}

.job-actions button.inactive svg {
	opacity: .3;
}

.job-actions button:first-child {
	float: left;
	margin-left: 0;
}

.job-actions .tip {
	display: block;
	min-width: 22px;
	height: 22px;
	padding: 0 8px;

	border-radius: 18px;

	font-size: 12px;
	text-align: center;
	line-height: 22px;
	font-weight: bold;

	background-color: var(--color-white);
	color: var(--color-black);

	position: absolute;
	top: -5px;
	left: 38px;
}

.job-actions {
	position: absolute;
	bottom: 15px;
	right: 15px;
	width: calc(100% - 30px);

	text-align: right;

	z-index: 5;

	opacity: 0;
	transform: translateY(10px);

	transition: opacity .25s ease, transform .25s ease;
}

.job-actions svg {
	display: block;
	width: 34px;
	height: 34px;
}

.job-tile:hover .job-actions {
	opacity: 1;
	transform: translateY(0px);
}

.archive,
.archive-this button {
	cursor: pointer;
}

.archive-this {
	display: flex;
	justify-content: center;
	flex-direction: column;

	text-align: center;

	background-color: var(--color-black);
	color: var(--color-white);

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 20px;


	border-radius: 25px;

	z-index: 10;
}

.archive-this button {
	position: static;
}

.archive-this__buttons {
	display: flex;
	flex-wrap: wrap;
}

.archive-this__buttons .btn {
	width: 50%;
	padding: 15px;
	text-align: right;
}

.archive-this__buttons .btn:last-child {
	text-align: left;
}

.archive-this__buttons .btn button {
	width: 100px;
}

.tags {
	display: block;
	padding: 0;
	margin: 10px 0 0;
}

.tags:empty {
	display: none;
}

.tags li {
	display: inline-block;
	background-color: var(--color-black);
	color: var(--color-white);
	border-radius: 15px;
	font-size: 12px;
	margin-right: 10px;
	padding: 5px 10px 6px;
}



.rating-bar {
	display: block;
	width: 100%;
	height: 34px;
	border-radius: 20px;
	border: 1px solid var(--color-black);

	overflow: hidden;
}

.rating-bar span {
	display: block;
	height: 34px;
	background-color: #C3D175;
}

.rating-actions {
	display: block;
}

.rating-actions ul {
	display: flex;
	flex-direction: row;
	padding: 0;
	margin: 0;
}

.rating-actions ul li {
	display: block;
	margin: 0 7px;
	padding: 0;
	position: relative;
	cursor: pointer;
}

.rating-actions ul li svg {
	display: block;
	border-radius: 50%;
}

.rating-actions ul li:before {
	content: '';
	display: block;
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	position: absolute;
	top: 0;
	left: 0;
	border: 3px solid white;
	border-radius: 50%;
	opacity: 0;

	transition: opacity .25s ease;
}

.rating-actions ul li:hover:before {
	opacity: 1;
}
</style>