import { createStore } from 'vuex';
import events from '../events';
import { mapState } from 'vuex';
import useFirebaseAuth from '../hooks/firebase-auth';
import * as data from './zip-codes.json';


let userType = 'user';

userType = 'employer';

const zips = [];
data.default.forEach((zipCode) => {
	zips.push(zipCode.nr + ' ' + zipCode.navn);
});

function is_touch_enabled() {
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );
}

let firstMatch = true;
export const store = createStore({
	state() {
		return {
			userType: userType,
			user: null,
			userData: null,
			page: null,
			authCheckLogin: false,
			jobs: null,
			pageTitle: null,
			applicants: null,
			activeJobCard: null,
			isTouch: is_touch_enabled(),
			zipCodes: zips,
		};
	},
	mutations: {
		setPageTitle(state, payload) {
			state.pageTitle = payload;
		},
		setAuthCheck(state, check) {
			state.authCheckLogin = check;
		},
		setUser(state, userData) {
			state.user = userData;
		},
		setUserData(state, userData) {
			state.userData = userData;
		},
		setApplicants(state, payload) {
			state.applicants = payload;
		},
		removeJob(state, payload) {
			state.jobs.find(x => x.id === payload.id).status = 'completed';
		},
		touchChange(state, payload) {
			state.isTouch = is_touch_enabled();
		},
		addToCv(state, payload) {
			console.log('userData: ', state.userData);
			console.log('payload: ', payload);

			if (!state.userData) {
				state.userData = {};
			}

			if (!state.userData.cv) {
				console.log('no userData: ', state.userData);
				state.userData.cv = [];
			}
			
			state.userData.cv.push(payload);
		},
		setCv(state, payload) {
			if (!state.userData.cv) {
				state.userData.cv = [];
			}
			
			state.userData.cv = payload;
		},
		addToSaved(state, payload) {
			state.saved.push(payload);
		},
		setActiveJobCard(state, payload) {
			state.activeJobCard = payload;
		},
		addToApplied(state, payload) {
			state.applied.push(payload);

			if (firstMatch) {
				firstMatch = false;

				const match = state.applied[0];
				state.matches.push(match);

				setTimeout(() => {
					events().emitter.emit('match', match.id);
				}, 7000);
			}
		},
		loadJobs(state, payload) {
			const sortetPayload = payload.sort(function(a, b) {
				var textA = a.title.toUpperCase();
				var textB = b.title.toUpperCase();
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			});

			state.jobs = sortetPayload;
		},
		addJob(state, payload) {
			let sortPayload = [];
			
			if (state.jobs != null) {
				sortPayload = [...state.jobs];
			}
			// const sortPayload = [...state.jobs];

			sortPayload.push(payload);

			const sortetPayload = sortPayload.sort(function(a, b) {
				var textA = a.title.toUpperCase();
				var textB = b.title.toUpperCase();
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			});
			
			state.jobs = sortetPayload;
		},
		addToMatches(state, payload) {
			state.matches.push(payload);
		},
		SET_ROUTE(state, route) {
			setTimeout(() => {
				state.page = route;
			}, 25);
		}
	}
});
