<template>
	<div class="input" :class="modifier">
		<span class="dot" v-if="modelValue"></span>
		<input type="checkbox" :name="name" :id="name" :checked="modelValue" @change="updateValue($event.target.checked)">
		<label v-if="label" :for="name">{{label}}</label>
	</div>
</template>

<script>
export default {
	name: 'checkbox',
	components: {},
	props: {
		type: String,
		label: String,
		placeholder: String,
		modelValue: Boolean,
		modifier: String,
		name: String,
		options: Array,
		selectDefault: String,
	},
	data () {
		return {
			selectValue: ''
		}
	},
	methods: {
		updateValue (value) {
			this.$emit('update:modelValue', value);
		},
	},
	created() {
		if (this.type == 'select') {
			// this.selectValue = options[0];
		}
	}
}
</script>

<style scoped>
label {
	display: inline-block;
	text-align: left;
	font-size: 16px;
	margin-bottom: 5px;
}

.input {
	display: block;
	position: relative;
}

.input + .input {
	margin-top: 15px;
}

.input input[type="checkbox"] {
	display: inline-block;
	width: 22px;
	height: 22px;
	padding: 14px;
	margin-right: 10px;
	appearance: none;

	border-radius: 15px;

	font-family: 'Degular';

	background-color: var(--color-light-grey);

	position: relative;
	bottom: -8px;
}

.input input[type="checkbox"]:checked {
	/*background-color: var(--color-black);*/
}

.input textarea {
	min-height: 120px;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

::-webkit-input-placeholder {
	color: var(--color-black);
}
::-moz-placeholder {
	color: var(--color-black);
}
:-moz-placeholder {
	color: var(--color-black);
}

.dot {
	display: block;
	width: 12px;
	height: 12px;

	border-radius: 50%;

	position: absolute;
	top: 16px;
	left: 8px;
	z-index: 5;

	pointer-events: none;

	background-color: var(--color-black);
}
</style>