<template>
	<IonPage>
		<IonContent>
			<div class="page-container">
				<transition name="fade-up">
					<div class="wrapper" v-if="showPage">
						<h1>Kontakt</h1>

						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
						<p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.</p>
						<p>Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.</p>
						<p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
					</div>
				</transition>
			</div>
		</IonContent>
	</IonPage>
</template>

<script>
import { mapState } from 'vuex';
import router from '../../router/index';
import useFirebaseAuth from '../../hooks/firebase-auth';
// import btn from '../../components/button.vue';

const {
	dbGet,
	dbAdd
} = useFirebaseAuth();

import {
    IonPage,
    IonContent
} from '@ionic/vue';

export default  {
	name: 'Contact',
	components: {
        IonPage,
        IonContent,
        // btn
	},
	watch: {
		
	},
	computed: {
		...mapState([
			'user',
			'userData',
			'page',
			'jobs'
		]),
		isActive() {
			return this.page == '/home'
		}
	},
	data: () => {
		return {
			showPage: false,
		}
	},
	mounted() {
		if (this.userData) {
			if (!this.userData.companyName) {
				this.$router.push({ path: '/contact' });
			}
		}

		setTimeout(() => {
			this.showPage = true;
		}, 200);
	},
	created() {
		if (!this.user) {
			this.$router.push({ path: '/' });
		}

		if (this.userData) {
			if (!this.userData.companyName) {
				this.$router.push({ path: '/profile' });
			}
		}
	},
}
</script>
<style scoped>

</style>