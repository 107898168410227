<template>
	<div class="btn" :class="modifier">
		<button :disabled="isDisabled">
			<svg v-if="modifiedWith('back')" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><defs><clipPath id="a"><path fill="none" d="M0 0h16v16H0z"/></clipPath></defs><g data-name="Arrow Left" clip-path="url(#a)"><path data-name="Path 10" d="m8 16 1.455-1.455-5.506-5.506H16V6.961H3.948l5.507-5.506L8 0 0 8Z"/></g></svg>
			<svg v-if="modifiedWith('close-x')" xmlns="http://www.w3.org/2000/svg" width="22.786" height="22.786"><path data-name="Union 3" d="m11.393 13.336-8.744 8.743-1.942-1.944 8.743-8.743L.707 2.649 2.649.707l8.743 8.743L20.135.707l1.944 1.942-8.743 8.743 8.743 8.743-1.944 1.944Z" fill="#fff" stroke="#fff"/></svg>
			{{text}}
		</button>
	</div>
</template>

<script>
export default {
	name: 'btn',
	components: {},
	props: {
		text: String,
		modifier: String,
		isDisabled: Boolean
	},
	methods: {
		modifiedWith(modWith) {
			if (this.modifier) {
				if (this.modifier.indexOf(modWith) >= 0) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}
	}
}
</script>

<style scoped>
.btn button {
	display: inline-block;
	appearance: none;
	padding: 12px 25px;
	appearance: none;
	border: none;
	outline: none;
	margin: 0;

	cursor: pointer;

	background-color: var(--color-black);

	color: var(--color-white);
	font-size: 15px;
	font-family: var(--font-family);
	text-transform: uppercase;
	border-radius: 25px; /*var(--radius-small);*/

	transition: background-color .2s ease;
}

.btn button[disabled],
.btn.inverted button[disabled] {
	background-color: var(--color-grey);
	cursor: default;
	opacity: .7;
}

.btn.block button {
	display: block;
	width: 100%;
}

.btn.back {
	display: inline-block;
}

.btn.back button {
	font-size: 20px;
	line-height: 1.1;
	font-weight: normal;
	color: var(--color-black);
}

.btn.back svg {
	margin-right: 10px;
	margin-bottom: -1px;
}

.btn.back button {
	display: inline-block;
	background-color: transparent;
	padding: 5px 0;
	color: var(--color-black);

	position: relative;
}

.btn.add button {
	background-color: transparent;
	padding: 0;
	color: var(--color-black);
	border-radius: 0;

	position: relative;
}

.btn.add button:before {
	content: '';
	display: inline-block;
	width: 22px;
	height: 22px;
	padding: 10px;
	border-radius: 50%;
	margin-right: 0;

	position: relative;
	bottom: -10px;

	background-color: var(--color-grey);

	pointer-events: none;
}

.btn.add button:after {
	content: '+';
	position: absolute;
	left: 8px;
	top: -6px;
	width: 14px;
	height: 14px;
	display: block;
	text-align: center;
	font-size: 52px;

	pointer-events: none;
}

.btn.inverted button {
	background-color: var(--color-white);
	color: var(--color-black);
}


.btn button[disabled] {

}

.btn.close-x button {
	width: 44px;
	height: 44px;
	padding: 0;
	margin: 0;
	border-radius: 50%;
	position: relative;
}

.btn.close-x button svg {
	display: block;
	width: 23px;
	height: 23px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>