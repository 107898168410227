<template>
	<IonPage>
		<IonContent>
			
			<transition name="fade-up">
				<div v-if="showPage" class="profile-page-item">
					<div class="page-container">
						<div class="cv wrapper">
							<!-- <btn modifier="back" @click="back"></btn> -->
							<h2>CV</h2>

							<div class="cv-items">

								<div class="cv-item" :class="{ 'deleting': deletingCvItemIndex == index }" v-for="(item, index) in cv" :key="index">
									<div class="cv-item__backdrop">
										<button @click="deleteSelectedCv">
											<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1"><path d="M356.65 450H171.47a41 41 0 0 1-40.9-40.9V120.66a15 15 0 0 1 15-15h237a15 15 0 0 1 15 15V409.1a41 41 0 0 1-40.92 40.9ZM160.57 135.66V409.1a10.91 10.91 0 0 0 10.9 10.9h185.18a10.91 10.91 0 0 0 10.91-10.9V135.66Z"/><path d="M327.06 135.66h-126a15 15 0 0 1-15-15V93.4a44.79 44.79 0 0 1 44.74-44.73h66.52a44.79 44.79 0 0 1 44.74 44.73v27.26a15 15 0 0 1-15 15Zm-111-30h96V93.4a14.75 14.75 0 0 0-14.74-14.73H230.8a14.75 14.75 0 0 0-14.73 14.73ZM264.06 392.58a15 15 0 0 1-15-15V178.09a15 15 0 1 1 30 0v199.49a15 15 0 0 1-15 15ZM209.9 392.58a15 15 0 0 1-15-15V178.09a15 15 0 0 1 30 0v199.49a15 15 0 0 1-15 15ZM318.23 392.58a15 15 0 0 1-15-15V178.09a15 15 0 0 1 30 0v199.49a15 15 0 0 1-15 15Z"/><path d="M405.81 135.66H122.32a15 15 0 0 1 0-30h283.49a15 15 0 0 1 0 30Z"/></g></svg>
										</button>
									</div>
									<div class="cv-item__inner">
										<p>
											<strong>{{item.title}}</strong>
											<span v-if="item.type == 'job'"> ({{item.company}})</span>
											<span v-if="item.type == 'education'"> ({{item.school}})</span>
										</p>
										<span class="small-text" v-if="item.end != 'present'">{{item.start}} — {{item.end}}</span>
										<span class="small-text" v-if="item.end == 'present'">{{item.start}} — nuværende</span>

										<span class="action-text small-text" @click="updateCv(item, index)">Rediger</span>
										<span class="action-text small-text" @click="deleteCv(index)">Slet</span>
									</div>
								</div>

								<div class="align-right">
									<btn modifier="add" @click="openModal"></btn>
								</div>

								<Modal v-show="isModalVisible" @close="closeModal" :modifierClass="'white'">
									<template v-slot:header>
									</template>

									<template v-slot:body>
										<div>
											<label>
												<input type="radio" name="type" value="job" v-model="addType">
												<span>Job</span>
											</label>
										</div>
										<div>
											<label>
												<input type="radio" name="type" value="education" v-model="addType">
												<span>Uddannelse</span>
											</label>
										</div>

										<br>

										<inputField label="Virksomhedsnavn" type="text" v-model="addCompanyName" v-if="addType == 'job'"></inputField>
										<inputField label="Stilling" type="text" v-model="addTitle" v-if="addType == 'job'"></inputField>

										<inputField label="Uddannelsessted" type="text" v-model="addSchool" v-if="addType == 'education'"></inputField>
										<inputField label="Titel" type="text" v-model="addSchoolTitle" v-if="addType == 'education'"></inputField>

										<checkbox label="Igangværende job/uddannelse" :name="'addTimePreset'" v-model="addTimePreset"></checkbox>

										<div class="cv-inputs" :class="{ 'cv-half-inputs': !addTimePreset }">
											<inputField label="Start" type="date" v-model="addTimeStart"></inputField>
											<inputField label="Slut" type="date" v-model="addTimeEnd" v-if="!addTimePreset"></inputField>
										</div>

										<!--inputField label="Kompetencer" type="text" v-model="addTags"></inputField-->
										<vue-tags-input v-model="tag" :placeholder="'Kvalifikationer (tryk enter for at tilføje)'" :tags="tags" @tags-changed="newTags => tags = newTags" />
										
									</template>

									<template v-slot:footer>
										<btn text="Gem" :isDisabled="!addFormIsValid" @click="save" v-if="modalActionType == 'add'" :modifier="'block'"></btn>

										<btn text="Gem" :isDisabled="!addFormIsValid" @click="edit" v-if="modalActionType == 'edit'" :modifier="'block'"></btn>
									</template>
								</Modal>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</IonContent>
	</IonPage>
</template>

<script>
import { mapState } from 'vuex';
import router from '../../router/index';
import inputField from '../../components/input.vue';
import checkbox from '../../components/checkbox.vue';
import btn from '../../components/button.vue';
import Modal from '../../components/modal.vue';
import VueTagsInput from '@sipec/vue3-tags-input';

import useFirebaseAuth from '../../hooks/firebase-auth';
const {
	setUserCv
} = useFirebaseAuth();

import {
    IonPage,
    IonContent
} from '@ionic/vue';

export default  {
	name: 'Cv',
	components: {
		VueTagsInput,
        IonPage,
        IonContent,
        btn,
        inputField,
        Modal,
        checkbox
	},
	computed: {
		...mapState([
			'user',
			'userData',
			'page',
		]),
		isActive() {
			return this.page == '/profile-cv'
		}
	},
	data: () => {
		return {
			showPage: false,
			addTitle: '',
			addCompanyName: '',
			addSchool: '',
			addSchoolTitle: '',
			addTimeStart: '',
			addTimeEnd: '',
			tag: '',
			tags: [],
			addType: 'job',
			addFormIsValid: false,
			addTimePreset: false,
			cv: null,
			isModalVisible: false,
			modalActionType: 'add',
			editCvItemIndex: null,
			deletingCvItemIndex: null
		}
	},
	mounted() {
		if (!this.user) {
			this.$router.push({ path: '/' });
		}

		if (this.userData) {
			this.cv = this.userData.cv;
		}

		setTimeout(() => {
			this.showPage = true;
		}, 100);
	},
	watch: {
		addTitle: function() {
			this.validateForm();
		},
		addTimeStart: function() {
			this.validateForm();
		},
		addTimeEnd: function() {
			this.validateForm();
		},
		userData: {
			handler: function() {
				if (this.userData) {
					if (this.userData.cv) {
						const sortedCv = this.userData.cv.sort(function(a,b){
							console.log('sort start: ', new Date(b.start))
							return new Date(b.start) - new Date(a.start);
						});
						this.cv = sortedCv;
					}
				}
			},
			deep: true
		}
	},
	methods: {
		deleteCv(index) {
			if (index == this.deletingCvItemIndex) {
				this.deletingCvItemIndex = null;
			} else {
				this.deletingCvItemIndex = index;
			}
		},
		deleteSelectedCv() {
			if (this.deletingCvItemIndex != null) {
				const deleteThis = this.deletingCvItemIndex;
				this.deletingCvItemIndex = null;

				setTimeout(() => {
					this.cv.splice(this.deletingCvItemIndex, 1);
					this.$store.commit('setCv', this.cv);
					setUserCv(this.userData.userId, { 'cv': this.userData.cv});
				}, 50);
			}
		},
		updateCv(item, index) {
			this.modalActionType = 'edit';

			if (item.type == 'job') {
				this.addTitle = item.title;
				this.addCompanyName = item.company;
				this.addTimeStart = item.start;
				this.addTimeEnd = item.end;
				this.tags = item.tags;
				this.addType = item.type;
			}

			if (item.type == 'education') {
				this.addSchool = item.school;
				this.addSchoolTitle = item.title;
				this.addTimeStart = item.start;
				this.addTimeEnd = item.end;
				this.tags = item.tags;
				this.addType = item.type;				
			}

			this.editCvItemIndex = index;
			this.addFormIsValid = false;
			this.isModalVisible = true;
		},
		validateForm() {
			let valid = true;

			if (this.addType == 'job') {
				if (this.addTitle.length == 0) {
					valid = false;
				}

				if (this.addCompanyName.length == 0) {
					valid = false;
				}

				if (this.addTimeStart.length == 0) {
					valid = false;
				}

				if (!this.addTimePreset) {
					if (this.addTimeEnd.length == 0) {
						valid = false;
					}
				}
			} else if (this.addType == 'education') {
				if (this.addSchoolTitle.length == 0) {
					valid = false;
				}

				if (this.addSchool.length == 0) {
					valid = false;
				}

				if (this.addTimeStart.length == 0) {
					valid = false;
				}

				if (!this.addTimePreset) {
					if (this.addTimeEnd.length == 0) {
						valid = false;
					}
				}
			}

			this.addFormIsValid = valid;
		},
		back: () => {
			router.push({ path: '/profile' });
		},
		edit() {
			let data = null;
			let timeEnd = this.addTimeEnd;

			if (this.addTimePreset) {
				timeEnd = 'present';
			}

			if (this.addType == 'job') {
				data = {
					'type': this.addType,
					'title': this.addTitle,
					'company': this.addCompanyName,
					'start': this.addTimeStart,
					'end': timeEnd,
					'tags': this.tags
				};
			} else if (this.addType == 'education') {
				data = {
					'type': this.addType,
					'title': this.addSchoolTitle,
					'school': this.addSchool,
					'start': this.addTimeStart,
					'end': timeEnd,
					'tags': this.tags
				};
			}

			if (data) {
				this.cv[this.editCvItemIndex] = data;
				this.$store.commit('setCv', this.cv);

				this.isModalVisible = false;

				// Reset model data
				this.addTitle = '';
				this.addCompanyName = '';
				this.addSchool = '';
				this.addSchoolTitle = '';
				this.addTimeStart = '';
				this.addTimeEnd = '';
				this.tags = [];
				this.addType = 'job';
				this.addFormIsValid = false;

				setUserCv(this.user.uid, { 'cv': this.userData.cv});
			}
		},
		save() {
			let data = null;
			let timeEnd = this.addTimeEnd;

			if (this.addTimePreset) {
				timeEnd = 'present';
			}

			if (this.addType == 'job') {
				data = {
					'type': this.addType,
					'title': this.addTitle,
					'company': this.addCompanyName,
					'start': this.addTimeStart,
					'end': timeEnd,
					'tags': this.tags
				};
			} else if (this.addType == 'education') {
				data = {
					'type': this.addType,
					'title': this.addSchoolTitle,
					'school': this.addSchool,
					'start': this.addTimeStart,
					'end': timeEnd,
					'tags': this.tags
				};
			}

			if (data) {
				console.log('data to add: ', data);
				this.$store.commit('addToCv', data);

				this.isModalVisible = false;

				// Reset model data
				this.addTitle = '';
				this.addCompanyName = '';
				this.addSchool = '';
				this.addSchoolTitle = '';
				this.addTimeStart = '';
				this.addTimeEnd = '';
				this.tags = [];
				this.addTimePreset = false;
				this.addType = 'job';
				this.addFormIsValid = false;

				console.log(this.userData.userId)

				setUserCv(this.userData.userId, { 'cv': this.userData.cv});
			}
		},
		openModal() {
			// Reset model data
			this.addTitle = '';
			this.addCompanyName = '';
			this.addSchool = '';
			this.addSchoolTitle = '';
			this.addTimeStart = '';
			this.addTimeEnd = '';
			this.tags = [];
			this.addType = 'job';
			this.addFormIsValid = false;
			
			this.modalActionType = 'add';
			this.isModalVisible = true;
		},
		closeModal() {
			this.isModalVisible = false;
		},
	}
}
</script>
<style scoped>
.align-right {
	text-align: center;
}

.profile-page-item {
	height: 100%;
	overflow: auto;
	background-color: var(--color-light-grey);
}

@media (min-width: 1024px) {
	.profile-page-item {
		padding-top: 74px;
	}

	.profile-page-item .page-container {
		padding-top: 40;
		padding-bottom: 40px;
	}
}

.page-container {
	height: 100%;
}

.fade-up-enter-active,
.fade-up-leave-active {
	transition: opacity .5s ease, transform .5s ease;
}


.fade-up-enter-from,
.fade-up-leave-to {
	opacity: 0;
	transform: translateY(20px);
}

.container {
}

.wrapper {
	height: 100%;
	min-height: 100%;
	overflow: auto;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
}

.cv {
	min-height: 100%;
	/*padding-bottom: 110px;*/
}

h2 {
	display: block;
	text-align: center;
}

.btn.back {
	position: absolute;
	z-index: 10;
}

.cv-items {
	margin-top: var(--padding);
	position: relative;
}

.cv-item {
	margin-bottom: var(--padding-inner);
	
	overflow: hidden;
	position: relative;

	border-radius: var(--radius);
}

.cv-item__inner {
	background-color: var(--color-white);
	padding: var(--padding-inner);

	position: relative;
	z-index: 5;

	transition: transform .3s ease;
}

.cv-item.deleting .cv-item__inner {
	transform: translateX(-70px);
}

.cv-item__inner:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	clear: both;
}

.cv-item__backdrop {
	display: none;
}

.cv-item__backdrop button {
	cursor: pointer;
	width: 70px;
	height: 100%;
	float: right;
}

.cv-item__backdrop button svg {
	width: 28px;
	height: 28px;
	fill: var(--color-white);
}

.cv-item p {
	margin-bottom: 7px;
}

.small-text {
	display: inline-block;
	float: left;
}

.action-text.small-text {
	display: inline-block;
	margin-left: 8px;
	float: right;
	text-align: right;
	text-transform: uppercase;
	color: var(--color-grey);

	cursor: pointer;

	transition: color .25s ease, opacity .25s ease;
}

.action-text.small-text:hover {
	color: var(--color-black);
	opacity: .7;
}

.cv-form {
	width: 100%;
	position: absolute;
	overflow: hidden;

	background-color: var(--color-white);
	border-radius: var(--radius);

	z-index: 10;

	opacity: 0;

	transition: top .3s ease, bottom .3s ease, opacity .25s ease;
}

.cv-form:not(.show) {
	pointer-events: none;
}

.cv-form-inner {
	height: 0;
	opacity: 0;

	padding: var(--padding-inner);

	transition: opacity .35s ease;
}

.cv-form.show .cv-form-inner {
	height: auto;
	opacity: 1;
}

.cv-form-inner .btn {
	margin-top: var(--padding);
	display: block;
	text-align: right;
}





.cv-inputs .input {
	margin-top: 15px;
	margin-bottom: 15px;
}

.cv-half-inputs .input {
	width: 100%;
}

@media (min-width: 1024px) {
	.cv-half-inputs {
		display: flex;
	}

	.cv-half-inputs .input {
		width: 50%;
		padding-right: 8px;
	}

	.cv-half-inputs .input:last-child {
		width: 50%;
		padding-left: 7px;
		padding-right: 0;
	}
}

.fade-up-enter-active,
.fade-up-leave-active {
	transition: opacity .3s ease, top .3s ease;
}

.fade-up-enter-from,
.fade-up-leave-to {
	opacity: 0;
	top: 15px;
}
</style>