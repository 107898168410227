<template>
	<div class="jobs">
		<transition name="fade-up">
			<div class="job-grid" v-if="showPage">
				<employerJob :type="'add'"></employerJob>
				<employerJob v-for="(job, index) in jobs" :job="job" :key="index" :type="'job'"></employerJob>
			</div>
		</transition>
	</div>
</template>

<script>
import employerJob from './employer-job.vue';
import { mapState } from 'vuex';
import useFirebaseAuth from '../../hooks/firebase-auth';

const {
	dbGetJobs
} = useFirebaseAuth();

export default {
	name: 'employerJobs',
	components: {
		employerJob
	},
	data: () => {
		return {
			showPage: false
		}
	},
	props: {
		jobList: Array
	},
	computed: {
		...mapState([
			'user',
			'userData',
			'jobs',
			'applicants'
		]),
	},
	methods: {
		loadJobs() {
			dbGetJobs(this.user.uid);
		},
	},
	mounted() {
		if (!this.jobs) {
			this.loadJobs();
		}

		setTimeout(() => {
			this.showPage = true;
		}, 100);
	}
}
</script>
<style>
.jobs {
	max-width: 1100px;
	margin:  0 auto;
}

@media (min-width: 1024px) {
	.page.user .jobs {
		max-width: 100%;
		overflow: auto;
		height: calc(100% - 74px);
		margin-top: 74px;
	}
}

.job-grid {
	width: calc(100% + 60px);
	display: flex;
	flex-wrap: wrap;
	padding-left: 15px;
	padding-right: 15px;
	margin-left: -30px;
	margin-top: 80px;
	margin-bottom: 80px;
}
</style>