<template>
	<transition name="modal-fade">
		<div class="modal-backdrop" :class="modifierClass">
			<button type="button" class="modal-close-backdrop" @click="close"></button>
			<div :class="{ 'modal-scroll employer': userType == 'employer', 'modal-scroll': userType != 'employer' }">
				<div class="modal" :class="{ 'has-actions': hasActions }">
					<div class="modal-header">
						<slot name="header"></slot>
						<btn modifier="block close-x" v-if="!modifierClass.includes('full')" @click="close"></btn>
						<btn text="tilbage" modifier="back" v-if="modifierClass.includes('full')" @click="close"></btn>
					</div>

					<div class="modal-body">
						<slot name="body"></slot>
					</div>

					<div class="modal-actions" v-if="hasActions">
						<slot name="actions"></slot>
					</div>

					<div class="modal-footer">
						<slot name="footer">
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import btn from './button.vue';
import { mapState } from 'vuex';

export default {
	name: 'Modal',
	components: {
		btn
	},
	props: {
		// modifierClass: String
		modifierClass: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapState([
			'userType',
		]),
	},
	data () {
		return {
			hasActions: false
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
	},
	created() {
		if (this.$slots.actions) {
			console.log('ACTIONS: ', this.$slots.actions());
			if (this.$slots.actions().length > 0) {
				this.hasActions = true;
			}
		}
	}
};
</script>

<style>
.modal .btn.close-x {
	margin-left: auto;
}

.employer .modal-backdrop.full .modal {
	height: 100%;
	border-radius: 0;
	padding-top: 20px;
	padding-bottom: 10px;
}
</style>
<style scoped>
.modal-backdrop.modal-fade-enter-active,
.modal-backdrop.modal-fade-leave-active {
	transition: opacity .3s ease, top .3s ease;
}

.modal-backdrop.modal-fade-enter-from,
.modal-backdrop.modal-fade-leave-to {
	opacity: 0;
	/*top: 15px;*/
}

.modal-backdrop {
	height: calc(100% - 74px);
	position: fixed;
	top: 74px;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;

	background-color: var(--color-white);

	transform: translate3d(0, 0, 0);

	transition: opacity .5s ease;
}

.modal-backdrop.full {
	background-color: var(--color-light-grey);
}

@media (min-width: 1024px) {
	.modal-backdrop.full {
		/*background-color: transparent;*/
	}
}

.modal-backdrop.full .modal-scroll {
	padding-top: 0;
	padding-bottom: 0;
}

.modal-backdrop.full .modal {
	/*height: 100%;*/
}

.modal-backdrop.full .modal-body {
	flex-grow:  1;
}

.page.user .modal-backdrop {
	height: 100%;
}

.modal-close-backdrop {
	display: block;
	width: 100%;
	height: 100%;

	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;
	background-color: transparent;
	border: none;
	appearance:  none;
	outline: none;
	opacity: 0;
}

.modal-scroll {
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 40px 0 100px;
	position: relative;
	z-index: 10;
	overflow: auto;
}

.modal {
	width: calc(100% - 36px);
	max-width: 100%;
	background: var(--color-white);
	border-radius: 25px;
	margin: 0 auto;
	overflow-x: auto;
	display: flex;
	flex-direction: column;

	position: relative;

	z-index: 20;
}

.employer .modal {
	background-color: var(--color-light-grey);
}

.modal.has-actions {
	padding-bottom: 70px !important;
}

@media (min-width: 1024px) {
	.modal-backdrop.full.user .modal {
		max-width: calc(1100px - 60px);
		height: calc(100% - 100px);
		padding: 0;
	}

	.modal-backdrop.full.user .modal .modal-body {
		padding: 0;
		margin: 0;
	}

	.modal-backdrop.full.user .modal .modal-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		padding: 20px;
	}

	.modal-backdrop.full.user .modal .modal-header .btn.back {
		margin-left: auto;
	}

	.modal-backdrop.full.user .modal .modal-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		padding: 20px;
	}
}

.modal .modal-header .btn.back {
	margin-left: auto;
}

.page.user .modal {
	padding-bottom: 50px;
	top: 20px;

	background-color: var(--color-grey);
}

@media (min-width: 1024px) {
	.page.user .modal {
		top: 74px;
	}
}

.page.user .modal-backdrop.white .modal {
	background-color: var(--color-white);
}

@media (min-width: 1024px) {
	.modal {
		max-width: 1100px;
	}
}

.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}

@media (min-width: 1024px) {
	.modal-backdrop.full .modal-header,
	.modal-backdrop.full .modal-footer {
		padding: 15px 0;
	}
}

.modal-backdrop.full {
	flex-direction: column;
	text-align: right;
}

.modal-backdrop.full .modal-body {
	text-align: left;
}

.modal-header {
	position: relative;
	justify-content: space-between;
}

.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
}

.modal-body {
	position: relative;
	padding: 20px 15px;
}

@media (min-width: 1024px) {
	.modal-backdrop.full .modal-body {
		padding: 20px 0;
		margin-top: 40px;
	}

	.modal-backdrop.full .modal-header {
		padding-top: 0;
		margin-top: -10px;
		align-self: flex-end;
		width: 100%;
	}

	.modal-backdrop.full .modal-header .btn {
		margin-left: auto;
	}
}

.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity .5s ease;
}

.modal-actions {
	background-color: var(--color-black);
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px 20px 20px;
	align-items: center;
}
</style>