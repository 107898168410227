import { toRefs, reactive } from 'vue';

import { store } from "../store/store";

import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';


import {
	getFirestore,
	collection,
	getDocs,
	getDoc,
	addDoc,
	doc,
	query,
	where,
	updateDoc,
	ref,
	// getStorage,
	uploadBytes
} from 'firebase/firestore';

// import 'firebase/firestore';
import {
	getAuth,
	getUserByEmail,
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	sendSignInLinkToEmail,
	isSignInWithEmailLink,
	signInWithEmailLink,
} from "@firebase/auth";

import useFirebaseStorage from './firestore-storage';
const {
	dbGetCompanyLogo
} = useFirebaseStorage();

const type = store.state.userType;

const actionCodeSettings = {
	url: `${window.location.origin}/email-login-${type}`,
	// url: 'hyre://email-login',
	handleCodeInApp: true,
	// iOS: {
	// 	bundleId: 'jobFair'
	// },
	// dynamicLinkDomain: 'hyre.page.link'
};
// 187883331469-ntj66qgvkjgacgi56nuu2abskjjbvoj3.apps.googleusercontent.com

import FIREBASE_CONFIG from '../.env.firebase';

if (initializeApp) {
	initializeApp(FIREBASE_CONFIG);
}

const db = getFirestore();
const usersRef = collection(db, 'users');
const jobsRef = collection(db, 'jobs');

export default function() {
	const state = reactive({
		user: null,
		initialized: false,
		error: null
	});

	const auth = getAuth();

	// Email only login
	const emailOnlyLogin = (email) => {
		const auth = getAuth();
		if (auth.currentUser != null) {
			return auth.currentUser;
		} else {
			return sendSignInLinkToEmail(auth, email, actionCodeSettings)
				.then((_user) => {
					state.user = _user;
					state.error = null;
					window.localStorage.setItem('emailForSignIn', email);
					return _user;
				})
				.catch((error) => {
					return error;
				});
		}
	}

	const validateEmailOnlyLogin = () => {
		const auth = getAuth();
		if (isSignInWithEmailLink(auth, window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');

			if (!email) {
				email = window.prompt('Please provide your email for confirmation');
			}

			return signInWithEmailLink(auth, email, window.location.href)
				.then((result) => {
					return result;
				})
				.catch((error) => {
					return false;
				});
		}
	}


	// Email and password
	const login = (email, password) => {
		const auth = getAuth();
		return signInWithEmailAndPassword(auth, email, password)
			.then((_user) => {
				state.user = _user;
				state.error = null;
				return _user;
			}, error => {
				state.error = error;
				throw error;
			});
	}

	const logout = () => {
		const auth = getAuth();
		return signOut(auth)
			.then(() => {
				state.user = null;
				return true;
			});
	};

	const setUserData = async (userId) => {
		const q = query(usersRef, where('userId', '==', userId));
		const querySnapshot = await getDocs(q);

		console.log('fewewffew: ', querySnapshot);

		if (querySnapshot.docs.length > 0) {
			store.commit('setUserData', querySnapshot.docs[0].data());
		} else {
			store.commit('setUserData', null);
		}

		return querySnapshot.docs;
	};

	const setUserCv = async (userId, cv) => {
		const q = query(usersRef, where('userId', '==', userId));
		const querySnapshot = await getDocs(q);

		if (querySnapshot.docs) {
			await updateDoc(querySnapshot.docs[0].ref, cv);

			return querySnapshot.docs[0].data();
		} else {
			return false
		}
	};

	const authCheck = () => {
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			let _user = null;
			if (user) {
				_user = user;

				store.commit('setUser', _user);
			}

			store.commit('setAuthCheck', true);

			state.user = _user;
			state.error = null;

			return _user;
		});
	};


	const dbGetJobs = async (employerId) => {
		const q = query(jobsRef, where('employerId', '==', employerId));
		const querySnapshot = await getDocs(q);
		let index = 0;
		const applicantsData = [];
		const newJobsData = [];

		querySnapshot.forEach((job) => {
			const jobDataCollection = job.data();
			jobDataCollection.id = job.id;

			let applicants = null;

			getJobApplicants(job.id)
				.then((applicantsList) => {
					jobDataCollection.applicants = applicantsList;

					if (applicantsList.length > 0) {
						applicantsData.push(applicantsList);
					}

					if (jobDataCollection.status) {
						if (jobDataCollection.status != 'archived') {
							newJobsData.push(jobDataCollection);
						}
					} else {
						newJobsData.push(jobDataCollection);
					}

					if (index === querySnapshot.docs.length - 1) {
						store.commit('loadJobs', newJobsData);
						store.commit('setApplicants', applicantsData);
					}

					index++;
				});			
		});
	};

	const archiveJob = async (jobId) => {
		const docRef = doc(db, 'jobs', jobId);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			await updateDoc(docRef, {'status': 'archived'});

			return true;
		} else {
			return false
		}
	};

	const updateJobInfo = async (jobId, data) => {
		const docRef = doc(db, 'jobs', jobId);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const updatedJob = await updateDoc(docRef, data);

			return updatedJob;
		} else {
			return false
		}
	};

	const getJobApplicants = async (jobId) => {
		const q = query(usersRef, where('applied', 'array-contains', jobId));
		const querySnapshot = await getDocs(q);
		const applicantsArray = [];

		querySnapshot.forEach(async (applicant) => {
			console.log(applicant.data());
			applicantsArray.push(applicant.data());
		});

		return applicantsArray;
	};

	const dbGetAllJobs = async (callback) => {
		const q = query(jobsRef);
		let index = 0;
		const querySnapshot = await getDocs(q);
		const newJobsData = [];

		querySnapshot.forEach(async (job) => {
			const data = job.data();
			data.id = job.id;

			const companyLogo = await dbGetCompanyLogo(data.employerId);

			data.companyLogo = companyLogo;

			newJobsData.push(data);

			if (index === querySnapshot.docs.length - 1) {
				store.commit('loadJobs', newJobsData);

				callback(true);
			}

			index++;
		});
	};

	const dbGet = async (type) => {
		const query = await getDocs(collection(db, type));

		return query;
	};

	const dbAddJob = async (data) => {
		try {
			const docRef = await addDoc(collection(db, 'jobs'), data);
			const setThisData = data;
			setThisData.id = docRef.id;
			setThisData.applicants = [];

			console.log(setThisData);

			store.commit('addJob', setThisData);

			return docRef;
		} catch (e) {
			console.error("Error adding document: ", e);
		}
	};

	const dbGetUser = async (userId) => {
		const q = query(usersRef, where('userId', '==', userId));
		const querySnapshot = await getDocs(q);

		return querySnapshot.docs;
	};

	const dbCreateUser = async (userId, type) => {
		try {
			if (type == 'user') {
				const docRef = await addDoc(collection(db, 'users'), {
					'userId': userId,
					'userType': type,
					'cv': [],
					'applied': [],
					'discarded': []
				});

				return docRef;
			} else {
				const docRef = await addDoc(collection(db, 'users'), {
					'userId': userId,
					'userType': type
				});

				return docRef;
			}
		} catch (e) {
			return e;
		}
	};

	const dbSetCompany = async (userId, data) => {
		const q = query(usersRef, where('userId', '==', userId));
		const querySnapshot = await getDocs(q);

		if (querySnapshot.docs) {
			await updateDoc(querySnapshot.docs[0].ref, data);

			store.commit('setUserData', querySnapshot.docs[0].data());

			return querySnapshot.docs[0].data()
		} else {
			return false
		}
	};

	const dbGetUsersJobStatus = async (userId, jobId) => {
		const q = query(usersRef, where('userId', '==', userId));
		const querySnapshot = await getDocs(q);
		const data = querySnapshot.docs[0].data();

		console.log('This snap: ', querySnapshot.docs[0].data());
		let jobStatus = 1;
		let appliedList = [];
		let discardedList = [];

		if (data.applied) {
			appliedList = data.applied;

			data.applied.forEach((appliedItem) => {
				if (appliedItem == jobId) {
					jobStatus = 2;
				}
			});
		} else if (data.discarded) {
			discardedList = data.discarded;

			data.discarded.forEach((appliedItem) => {
				if (appliedItem == jobId) {
					jobStatus = 0;
				}
			});
		}

		return {
			'status': jobStatus,
			'appliedList': appliedList,
			'discardedList': discardedList
		};
	};

	const dbSetUsersJobStatus = async (userId, jobId, setStatusTo) => {
		const q = query(usersRef, where('userId', '==', userId));
		const querySnapshot = await getDocs(q);
		let appliedList = [];
		let discardedList = [];

		if (querySnapshot.docs.length == 0) {
			return false;
		}
		
		if (setStatusTo == 2) {
			// Apply
			if (store.state.userData.applied) {
				appliedList = store.state.userData.applied;
			}

			appliedList.push(jobId);

			store.state.userData.applied = appliedList;

			await updateDoc(querySnapshot.docs[0].ref, {
				'applied': appliedList
			});
		} else if (setStatusTo == 0) {
			// Discard
			if (store.state.userData.discarded) {
				discardedList = store.state.userData.discarded;
			}

			discardedList.push(jobId);

			store.state.userData.discarded = discardedList;

			await updateDoc(querySnapshot.docs[0].ref, {
				'discarded': discardedList
			});
		}

		// if (querySnapshot.docs) {
		// 	await updateDoc(querySnapshot.docs[0].ref, data);

		// 	store.commit('setUserData', querySnapshot.docs[0].data());

		// 	return querySnapshot.docs[0].data()
		// } else {
		// 	return false
		// }
	};

	return {
		...toRefs(state),
		// FUNCTIONS
		// createUser,
		login,
		logout,
		authCheck,
		emailOnlyLogin,
		validateEmailOnlyLogin,
		dbGet,
		dbAddJob,
		dbGetJobs,
		dbGetAllJobs,
		dbGetUser,
		dbCreateUser,
		dbSetCompany,
		setUserData,
		setUserCv,
		dbGetUsersJobStatus,
		dbSetUsersJobStatus,
		archiveJob,
		updateJobInfo
	};
}