<template>
	<IonPage>
		<IonContent>
			<div class="page-container">
				<transition name="fade-up">
					<div class="home" v-if="isActive">
						<div class="wrapper">
							<h1>Profile</h1>

							<div class="profile-widgets">
								<div class="widget widget-full" data-theme="" @click="open($event, 'cv')">
									<div class="widget-content">
										<h2>CV</h2>
									</div>
								</div>

								<div class="widget" data-theme="theme-1" @click="open($event, 'values')">
									<div class="widget-content">
										<h2>Values</h2>
									</div>
								</div>

								<div class="widget" data-theme="theme-3" @click="open($event, 'goals')">
									<div class="widget-content">
										<h2>Goals</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</IonContent>
	</IonPage>
</template>

<script>
import router from '../router/index';
import { mapState } from 'vuex';

import {
    IonPage,
    IonContent
} from '@ionic/vue';

export default  {
	name: 'Profile',
	components: {
        IonPage,
        IonContent
	},
	computed: {
		...mapState([
			'page',
			'user'
		]),
		isActive() {
			return this.page == '/profile'
		}
	},
	data: () => {
		return {
			showPage: false
		}
	},
	created() {
		if (!this.user) {
			console.log('user not set')
			this.$router.push({ path: '/' });
		}
	},
	mounted() {
		setTimeout(() => {
			this.showPage = true;
		}, 100);
	},
	methods: {
		open: (event, widget) => {
			event.target.classList.add('display');

			setTimeout(() => {
				router.push({ path: '/profile-' + widget });
			}, 100);
		}
	}
}
</script>
<style scoped>
.home {
	height: calc(100vh - 135px);
}

.wrapper {
	height: 100%;
}

.profile-widgets {
	/*height: 100%;*/
	height: calc(100% - 70px);
	display: flex;
	flex-wrap: wrap;
	margin-left: calc(var(--padding) * -0.5);
	margin-right: calc(var(--padding) * -0.5);
}

.widget {
	width: 50%;
	height: 60%;
	padding: 0 calc(var(--padding) * 0.5);
}

.widget-full {
	height: 40%;
	width: 100%;
}

.widget-content {
	height: calc(100% - var(--padding));
	padding: var(--padding-inner);
	background-color: var(--theme);
	margin-bottom: var(--padding);

	border-radius: var(--radius);

	position: relative;
}

.widget-content:before {
	content: '';
	display: block;
	width: 0;
	height: 0;

	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 999999;

	border-radius: 50%;
	transform: translate(-50%,-50%);

	background-color: var(--theme);

	transition: width .55s ease, height .55s ease;
}

.widget-content.display:before {
	width: 400vw;
	height: 400vw;
}

.widget-content:after {
	content: 'Edit';
	text-transform: uppercase;

	position: absolute;
	bottom: var(--padding-inner);
	right: var(--padding-inner);
	font-size: 14px;
}

.widget-content h2 {
	font-size: 30px;
	line-height: 30px;
}

.fade-up-enter-active,
.fade-up-leave-active {
	transition: opacity .3s ease, top .3s ease;
}

.fade-up-enter-from,
.fade-up-leave-to {
	opacity: 0;
	top: 15px;
}
</style>