<template>
	<IonPage>
		<IonContent>
			<div class="page-container" data-theme="theme-1">
				<transition name="fade-up">
					<div class="values wrapper" v-if="isActive" data-theme="theme-1">
						<btn modifier="back" @click="back"></btn>
						<h2>Values</h2>
					</div>
				</transition>
			</div>
		</IonContent>
	</IonPage>
</template>

<script>
import { mapState } from 'vuex';
import router from '../../router/index';
// import inputField from '../../components/input.vue';
import btn from '../../components/button.vue';

import {
    IonPage,
    IonContent
} from '@ionic/vue';

export default  {
	name: 'Values',
	components: {
        IonPage,
        IonContent,
        btn
	},
	computed: {
		...mapState([
			'user',
			'page',
		]),
		isActive() {
			return this.page == '/profile-values'
		}
	},
	data: () => {
		return {
			showPage: false,
		}
	},
	mounted() {
		if (!this.user) {
			this.$router.push({ path: '/' });
		}
	},
	watch: {
		
	},
	methods: {
		back: () => {
			router.push({ path: '/profile' });
		},
	}
}
</script>
<style scoped>
.container {
}

.wrapper {
	height: 100%;
	min-height: 100%;
	overflow: auto;
}

.values {
	min-height: 100%;
	padding-bottom: 110px;
}

h2 {
	display: block;
	text-align: center;
}

.btn.back {
	position: absolute;
	z-index: 10;
}
</style>