<template>
	<div class="menu" :class="userType" v-if="userIsSet">
		<router-link to="/" v-if="type == 'user'" class="user-logo">
			<svg data-name="Group 871" xmlns="http://www.w3.org/2000/svg" width="132.001" height="51"><defs><clipPath id="a"><path data-name="Rectangle 1655" fill="none" d="M0 0h132.001v51H0z"/></clipPath></defs><g data-name="Group 870" clip-path="url(#a)"><path data-name="Path 154" d="M23 .13a23 23 0 0 0-23 23v23.889h46V23.137a23 23 0 0 0-23-23" fill="#292929"/><path data-name="Path 155" d="M23.003 11.188a12.165 12.165 0 0 0-12.165 12.165v24.771a2.875 2.875 0 0 0 2.875 2.871h18.579a2.875 2.875 0 0 0 2.875-2.875V23.353a12.165 12.165 0 0 0-12.164-12.165" fill="#fff"/><path data-name="Rectangle 1652" fill="#292929" d="M9.47 29.767h27.426v8.847H9.47z"/><path data-name="Rectangle 1653" fill="#292929" d="M51.101.602h10.895V46.83H51.101z"/><path data-name="Path 156" d="M66.994 46.83V.6h10.413v7.857h-.262c.963-2.648 3.357-4.719 5.585-6.215A13.978 13.978 0 0 1 90.71 0a13.454 13.454 0 0 1 7.93 2.273 12.067 12.067 0 0 1 4.559 6.184h.049a13.634 13.634 0 0 1 5.625-6.157 15.951 15.951 0 0 1 8.682-2.3 14.093 14.093 0 0 1 10.413 4.033q4.033 4.034 4.033 11.767v31.03h-10.924V19.255q0-4.3-2.287-6.305a8.2 8.2 0 0 0-5.6-2 7.863 7.863 0 0 0-6.155 2.453 9.14 9.14 0 0 0-2.212 6.365V46.83H94.145V18.8a7.37 7.37 0 0 0-7.765-7.855 7.979 7.979 0 0 0-4.274 1.189 8.535 8.535 0 0 0-3.069 3.341 10.521 10.521 0 0 0-1.143 5.011V46.83Z" fill="#292929"/><path data-name="Rectangle 1654" fill="#292929" d="M67.026.602h10.895V46.83H67.026z"/></g></svg>
		</router-link>
		<ul v-if="type == 'user'" class="user-menu">

			<li :class="{ 'is-active': isActive('/jobs/jobs') }">
				<router-link to="/jobs/jobs">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.66 28.38"><g data-name="Group 797"><g data-name="Ellipse 300"><circle cx="12.58" cy="12.58" r="12.58" style="fill:none"/><path d="M12.57 25.17A12.59 12.59 0 0 1 12.36 0h.22a12.59 12.59 0 0 1 9 21.33 12.47 12.47 0 0 1-8.84 3.83ZM12.38 1v1a10.67 10.67 0 1 0 .18 0h-.18Z"/></g><path d="m19.947 21.362 1.496-1.328 6.219 7.01-1.497 1.327z" data-name="Line 10"/></g></svg>
					Jobs
				</router-link>
			</li>
			<li :class="{ 'is-active': isActive('/profile-cv') }">
				<router-link to="/profile-cv">
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path class="fill" data-name="Union 1" d="M1 29v-3.5c0-3.851 6.3-7 14-7s14 3.15 14 7V29ZM8 8a7 7 0 1 1 7 7 7 7 0 0 1-7-7Z" fill="none" stroke="#000" stroke-width="2"/></svg>
					CV
				</router-link>
			</li>
			<!-- <li :class="{ 'is-active': isActive('/saved') }">
				<router-link to="/jobs/saved">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30.21"><path data-name="Path 110" d="M294.29 381.79h23v27l-11.5-10.13-11.5 10.13Z" transform="translate(-293.29 -380.79)" style="fill:none;stroke:#000;stroke-width:2px"/></svg>
					Saved
				</router-link>
			</li> -->
			<!-- <li :class="{ 'is-active': isActive('/jobs/matches') }">
				<router-link to="/jobs/matches">
					<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31"><g data-name="Ellipse 298" fill="none" stroke="#000" stroke-width="2"><circle cx="15.5" cy="15.5" r="15.5" stroke="none"/><circle cx="15.5" cy="15.5" r="14.5"/></g></svg>
					Matches
				</router-link>
			</li> -->
			<li>
				<a @click="doLogout">
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path class="fill" data-name="Union 1" d="M1 29v-3.5c0-3.851 6.3-7 14-7s14 3.15 14 7V29ZM8 8a7 7 0 1 1 7 7 7 7 0 0 1-7-7Z" fill="none" stroke="#000" stroke-width="2"/></svg>
					Log ud
				</a>
			</li>
		</ul>

		<div class="wrapper" v-if="type == 'employer'">
			<svg id="Group_1020" data-name="Group 1020" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 417.049 151.874" width="417.049" height="151.874"><defs><clipPath id="clip-path"><path id="Rectangle_1750" data-name="Rectangle 1750" fill="none" d="M0 0h417.049v151.874H0z"/></clipPath></defs><g id="Group_1019" data-name="Group 1019" clip-path="url(#clip-path)"><path id="Path_295" data-name="Path 295" d="M99.386 114.195h-2.8c-7.143 23.915-25.777 37.581-51.556 37.581C17.7 151.775 0 139.352 0 111.4c0-25.157 16.151-43.482 51.556-43.482h46.587v-11.8c0-21.74-8.385-33.542-31.989-33.542-19.567 0-30.437 9.628-35.1 28.883L5.592 45.246C11.493 17.293 32.3.212 67.086.212c40.374 0 58.078 19.566 58.078 55.594v92.864H99.386Zm-1.243-19.566v-8.075H55.6c-18.946 0-29.816 7.454-29.816 21.74 0 11.491 7.143 21.741 28.573 21.741 26.711 0 43.792-14.909 43.792-35.407" transform="translate(0 .099)"/><path id="Rectangle_1749" data-name="Rectangle 1749" transform="translate(141.572 3.727)" d="M0 0h27.02v145.041H0z"/><path id="Path_296" data-name="Path 296" d="M154.607 148.768h-27.021V3.727h26.4v37.58h2.8C164.856 15.219 183.49 0 209.269 0c28.573 0 42.548 17.083 45.345 43.481h2.8C264.242 16.772 281.323 0 310.208 0c32.3 0 47.517 21.12 47.517 52.8v95.969h-27.02V59.321c0-19.877-8.7-34.784-31.369-34.784-26.4 0-43.169 16.46-43.169 41.307v82.924h-27.021V59.321c0-19.877-8.7-34.784-31.368-34.784-25.778 0-43.171 16.463-43.171 41.307Z" transform="translate(59.322)"/></g></svg>
			<ul class="employer-list" :class="{ 'show-menu': showMenu }">
				<li :class="{ 'is-active': isActive('/home') }">
					<router-link to="/home">
						Jobs
					</router-link>
				</li>
				<li :class="{ 'is-active': isActive('/profile') }">
					<router-link to="/profile">
						Profil
					</router-link>
				</li>
				<!--li :class="{ 'is-active': isActive('/contact') }">
					<router-link to="/contact">
						Kontakt
					</router-link>
				</li-->
				<li>
					<a @click="doLogoutEmployer">
						Log ud
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
// import btn from './button.vue';

import useFirebaseAuth from '../hooks/firebase-auth';
const { logout } = useFirebaseAuth();

export default {
	name: 'bottomMenu',
	props: {
		type: String,
		showMenu: Boolean
	},
	data: () => {
		return {
			userIsSet: false
		}
	},
	watch: {
		userData: {
			handler: async function(userData) {
				if (this.userData) {
					console.log(this.userData.userType, this.userType)
					if (this.userData.userType == this.userType) {
						if (this.userType == 'employer') {
							if (userData) {
								if (userData.companyName) {
									this.userIsSet = true;
								}
							}
						} else {
							this.userIsSet = true;
						}
					} else {
						this.userIsSet = false;

						const status = await logout();
					}
				}
			}
		}
	},
	components: {
	},
	computed: {
		...mapState([
			'page',
			'user',
			'userType',
			'userData'
		]),
		isActive(path) {
			return (path) => this.page == path;
		}
	},
	methods: {
		async doLogout() {
			const status = await logout();
			this.$store.commit('setUser', null);
			this.$store.commit('setUserData', null);
		},
		async doLogoutEmployer() {
			const status = await logout();
			this.$store.commit('setUser', null);
			this.$store.commit('setUserData', null);
		}
	},
	mounted() {
		console.log('Type: ', this.userType)
		if (this.userType == 'employer') {
			if (this.userData) {
				if (this.userData.userType == this.userType) {
					if (this.userData.companyName) {
						this.userIsSet = true;
					}
				}
			}
		} else {
			// if (this.userData.userType == this.userType) {
			// 	this.userIsSet = true;
			// }
		}
	}
}
</script>

<style scoped>
.menu {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999999;

	padding: 15px var(--padding);

	background-color: var(--color-light-grey);
}

.menu.employer {
	position: fixed;
	top: 0;
	left: 0;
	bottom: auto;
	padding: 0;
	background-color: var(--color-white);
}

.menu.employer svg {
	position: absolute;
	width: auto;
	height: 40px;
	left: var(--padding-large);
	top: 20px;
}

ul {
	display: flex;
	flex-wrap:  wrap;
	justify-content: space-around;

	width: 100%;
	padding: 0;
	margin: 0;
}

ul li {
	display: block;
	width: 33.33%;
	margin: 0;
}

ul li a {
	display: block;
	text-align: center;
	font-size: 10px;
	text-transform: uppercase;
	text-decoration: none;
	color: var(--color-black);
	cursor: pointer;
}

ul li.is-active svg .fill {
	fill: black;
	stroke: black;
}

ul li.is-active a {
	text-decoration: underline;
}

ul.user-menu li a {
	display: inline-block;
}

ul.user-menu li {
	text-align: center;
}

svg {
	display: block;
	width: 100%;
	height: 26px;
	margin-bottom: 5px;
}

.employer-list {
	height: 74px;
	display: block;
	text-align: right;
	background-color: var(--color-white);

	padding: var(--padding) 0;
	margin-top: 0px;

	/*transform:  translateY(-100%);*/
}

.employer-list li {
	display: inline-block;
	width: auto;
	text-align: left;
	margin-left: 15px;
}

.employer-list li a {
	display: inline-block;
	font-size: 20px;
	text-transform: none;
	font-weight: normal;
	text-align: left;
	text-transform: uppercase;
	padding: 11px 22px;
	border: 1px solid var(--color-black);
	border-radius: 30px;
	line-height: 1;
	font-weight: 600;

	transition: background-color .3s ease, border-color .3s ease;
}

.employer-list li.is-active a,
.employer-list li a:hover {
	text-decoration: none;
	background-color: var(--color-brand);
	/*border-color: var(--color-brand);*/
}

/*.employer-list li a:hover {
	color: var(--color-grey-light);
}

.employer-list li.is-active a {
	text-decoration: none;
	color: var(--color-white);
}*/

.user-logo {
	display: none;
}

@media (min-width: 1024px) {
	.menu.user {
		display: flex;
		flex-direction: row;
		bottom: auto;
		top: 0px;
		background-color: var(--color-white);
		height: 74px;
		padding: 14px var(--padding);
	}

	.user-logo {
		display: block;
		width: 200px;
	}

	.user-logo svg {
		display: block;
		height: 46px;
		/*width: 200px;*/
		margin-bottom: 0;
	}

	.user-menu {
		display: block;
		text-align: right;
	}

	ul.user-menu li {
		display: inline-block;
		width: auto;
		margin-left: 18px;
	}

	ul.user-menu li svg {
		display: none;
	}

	ul.user-menu li a {
		font-size: 24px;
		text-transform: uppercase;
		padding: 3px 18px;
		border: 1px solid var(--color-black);
		border-radius: 20px;
		text-decoration: none;
	}

	ul.user-menu li.is-active a {
		background-color: var(--color-black);
		color: var(--color-white);
	}
}
</style>