import { toRefs, reactive } from 'vue';
import { store } from "../store/store";

import { initializeApp } from 'firebase/app';

import {
	getStorage,
	ref,
	uploadBytes,
	uploadString,
	getDownloadURL
} from 'firebase/storage';

import FIREBASE_CONFIG from '../.env.firebase';

if (initializeApp) {
	initializeApp(FIREBASE_CONFIG);
}

const storage = getStorage();

export default function() {
	const dbSetCompanyLogo = async (userId, file) => {
		const reader = new FileReader();
		const fileExtension = file.name.split('.').pop();
		const logoRef = ref(storage, userId + '/logo.' + fileExtension);

		reader.addEventListener('load', function () {
			uploadString(logoRef, reader.result, 'data_url').then((snapshot) => {
			});
		}, false);

		reader.readAsDataURL(file);
	};

	const dbGetCompanyLogo = async (userId) => {
		const pathReference = await getDownloadURL(ref(storage, userId + '/logo.jpg'))
			.then((url) => {
				return url;
			})
			.catch((error) => {
				return null;
			});

		return pathReference;
	};

	return {
		// ...toRefs(state),
		// FUNCTIONS
		dbSetCompanyLogo,
		dbGetCompanyLogo
	};
}