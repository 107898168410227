import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
import Login from '../views/login.vue'
import EmailLogin from '../views/email-login-user.vue'
import Profile from '../views/profile.vue'
import Jobs from '../views/jobs.vue'
import Saved from '../views/saved.vue'
import Matches from '../views/matches.vue'
import Cv from '../views/profile/cv.vue'
import Values from '../views/profile/values.vue'
import Goals from '../views/profile/goals.vue'

import Employer from '../views/employer/index.vue'
import EmployerLogin from '../views/employer/login-employer.vue'
import EmployerProfile from '../views/employer/profile.vue'
import EmailLoginEmployer from '../views/employer/email-login-employer.vue'
import EmployerContact from '../views/employer/contact.vue'

// import { mapState } from 'vuex'
import { store } from "../store/store";

const type = store.state.userType;
let routes;

if (type == 'user') {
	routes = [
		{
			path: '/', component: Login
		}, {
			path: '/email-login-' + store.state.userType, component: EmailLogin
		}, {
			path: '/profile', component: Profile
		}, {
			path: '/profile-cv', component: Cv
		}, {
			path: '/profile-values', component: Values
		}, {
			path: '/profile-goals', component: Goals
		}, {
			path: '/jobs/:list', component: Jobs, props: true
		}, {
			path: '/saved', component: Saved
		}, {
			path: '/matches', component: Matches
		}
	];
} else if (type == 'employer') {
	routes = [
		{
			path: '/', component: EmployerLogin, name: 'Login'
		}, {
			path: '/profile', component: EmployerProfile, name: 'Profil'
		}, {
			path: '/contact', component: EmployerContact, name: 'Kontakt'
		}, {
			path: '/email-login-' + store.state.userType, component: EmailLoginEmployer, name: 'Login User'
		}, {
			path: '/home', component: Employer, name: 'Jobs'
		}
	];
}

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	mode: 'history',
	routes,
	beforeRouteUpdate(to, from, next) {
		next();
	}
})

router.beforeEach((to, from, next) => {
	store.commit('SET_ROUTE', to.path);
	next();
});

export default router