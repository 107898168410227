// .env.firebase.ts
// const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyA4nqghZ5AAQHFfE2IIEcD4GmKsJPktAZg",
//     authDomain: "hyreoauth2.firebaseapp.com",
//     databaseURL: "https://hyreoauth2.firebaseio.com",
//     projectId: "hyreoauth2",
//     storageBucket: "hyreoauth2.appspot.com",
//     messagingSenderId: "87HPWowFm2w999",
//     appId: "1:00000031465:web:53a3bHPWowFm2wc6a38",
//     measurementId: "G-aSyDI9wPjgY",
// };

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyAmTddsQq3pw9weXSYVp-5udbrxCLe9-yE",
    authDomain: "aim-jobs.firebaseapp.com",
    projectId: "aim-jobs",
    storageBucket: "aim-jobs.appspot.com",
    messagingSenderId: "263911695014",
    appId: "1:263911695014:web:366c1cbc0360f161f18a67"
};
  
export default FIREBASE_CONFIG 