<template>
	<div class="input" :class="modifier">
		<label v-if="label" :for="name">{{label}}</label>
		<input v-if="type != 'textarea' && type != 'select' && type != 'checkbox'" :id="name" :type="type" :placeholder="placeholder" :value="modelValue" @keyup="updateValue($event.target.value)" @change="updateValue($event.target.value)"/>

		<textarea v-if="type == 'textarea'" :id="name" :type="type" :placeholder="placeholder" :value="modelValue" @keyup="updateValue($event.target.value)" @change="updateValue($event.target.value)"></textarea>

		<select v-if="type == 'select'" :value="modelValue" @change="updateValue($event.target.value)">
			<option :selected="true" :value="''">{{selectDefault}}</option>
			<option v-for="(option, index) in options" :value="option" :key="index">{{option}}</option>
		</select>

	</div>
</template>

<script>
export default {
	name: 'inputField',
	components: {},
	props: {
		type: String,
		label: String,
		placeholder: String,
		modelValue: String,
		modifier: String,
		name: String,
		options: Array,
		selectDefault: String,
	},
	data () {
		return {
			selectValue: ''
		}
	},
	methods: {
		updateValue (value) {
			this.$emit('update:modelValue', value);
		},
	},
	created() {
		if (this.type == 'select') {
			// this.selectValue = options[0];
		}
	}
}
</script>

<style scoped>
label {
	display: block;
	text-align: left;
	font-size: 16px;
	margin-bottom: 5px;
}

.input {
	display: block;
}

.input + .input {
	margin-top: 15px;
}

.input input:not([type="checkbox"]),
.input textarea,
.input select {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 17px 20px;
	appearance: none;

	border-radius: 15px;

	font-family: 'Degular';

	background-color: var(--color-light-grey);

	color: var(--color-black);
	font-size: 16px;
	line-height: 1.3;
}

.input textarea {
	min-height: 120px;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

::-webkit-input-placeholder {
	color: var(--color-black);
}
::-moz-placeholder {
	color: var(--color-black);
}
:-moz-placeholder {
	color: var(--color-black);
}
</style>