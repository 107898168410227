<template>
	<IonPage>
		<IonContent>
			<div class="page-container">
				<transition name="fade-up">
					<div class="saved" v-if="isActive">
						<div class="wrapper">
							<h1>Saved</h1>

							<ul>
								<li v-for="item in saved" :key="item.id"><p v-html="getJobTitle(item.id)"></p></li>
							</ul>
						</div>
					</div>
				</transition>
			</div>
		</IonContent>
	</IonPage>
</template>

<script>
import { mapState } from 'vuex';

import {
    IonPage,
    IonContent
} from '@ionic/vue';

export default  {
	name: 'Saved',
	components: {
        IonPage,
        IonContent
	},
	computed: {
		...mapState([
			'user',
			'page',
			'saved',
			'jobs'
		]),
		isActive() {
			return this.page == '/saved'
		}
	},
	data: () => {
		return {
			showPage: false
		}
	},
	mounted() {
		if (!this.user) {
			this.$router.push({ path: '/' });
		}
	},
	methods: {
		getJobTitle(id) {
			console.log('id: ', id, this.jobs.find(x => x.id === id).title);
			// this.jobs.findIndex(x => x.id === payload.id);
			return this.jobs.find(x => x.id === id).title;
		}
	}
}
</script>
<style scoped>
ul {
	display: block;
	padding: 0;
	margin: 0;
}

ul li {
	display: block;
	list-style-type: none;
	font-size: 18px;
}
</style>