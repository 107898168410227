<template>

	<transition name="fade-up">
		<div class="cards" v-if="jobsLoaded">
			<card v-for="(cardData, index) in jobsList" :activeCardIndex="activeCardIndex" :key="index" :cardData="cardData" :status="cardData.status" :list="list"></card>
		</div>
	</transition>
	<transition name="fade-up-slow">
		<div class="rest-a-little" v-if="jobsLoaded">
			<span class="icon">💆</span>
			<p>Nothing in the {{list}} list</p>
			<p>Rest a little</p>
			<span class="rest-a-little__border"></span>
		</div>
	</transition>

</template>

<script>
import events from '../events';
import card from '../components/card.vue';
import { mapState } from 'vuex';
import { store } from "../store/store";
import useFirebaseAuth from '../hooks/firebase-auth';

const {
	dbGetAllJobs
} = useFirebaseAuth();

export default {
	name: 'cards',
	components: {
		card,
	},
	data: () => {
		return {
			choice: '',
			noMoreCards: false,
			jobsLoaded: false,
			jobsList: [],
			activeCardIndex: null
		}
	},
	props: {
		list: String
	},
	computed: {
		...mapState([
			'jobs',
			'userData',
		]),
		// activeCardIndex: function() {
		// 	const count = this.jobsList.length;
		// 	return count - 1;
		// }
	},
	watch: {
		list: function (val) {
			
		},
		userData: function () {
			if (this.jobs) {
				this.updateJobsList();
			}
		},
		jobs: function () {
			this.updateJobsList();
		}
	},
	methods: {
		prepareData(data) {
			const returnData = [];
			data.forEach((dataItem) => {
				if (dataItem.title) {
					returnData.push(dataItem);
				} else {
					const foundData = this.jobs.find(x => x.id === dataItem.id);
					returnData.push(foundData);
				}
			});

			return returnData;
		},
		updateJobsList(jobs) {
			const loadJobs = [];
			
			this.jobs.forEach((job) => {
				let addJob = true;

				if (this.userData) {
					if (this.userData.applied) {
						if (this.userData.applied.includes(job.id)) {
							addJob = false;
						}
					}

					if (this.userData.discarded) {
						if (this.userData.discarded.includes(job.id)) {
							addJob = false;
						}
					}
				}

				if (job.status == 'archived') {
					addJob = false;
				}

				if (job.status == 'draft') {
					addJob = false;
				}

				if (addJob) {
					loadJobs.push(job);
				}
			});

			this.jobsList = loadJobs;
			this.activeCardIndex = this.jobsList.length - 1;
		}
	},
	mounted() {
		events().emitter.on('jobsLoaded', (payload) => {
			this.jobsLoaded = true;
		});

		if (this.jobs != null) {
			this.jobsLoaded = true;
		}

		events().emitter.on('setCard', (payload) => {
			this.activeCardIndex = this.activeCardIndex - 1;

			if (payload.removeFromList) {
				console.log('ID: ', payload.id);

				const selectedJobIndex = this.jobsList.findIndex(item => item.id === payload.id);

				if (selectedJobIndex) {
					this.jobsList.splice(selectedJobIndex, 1);
				}
			}
		});

		if (this.jobs) {
			this.updateJobsList();
		}
	}
}
</script>

<style scoped>
.fade-up-form-enter-active,
.fade-up-form-leave-active {
	transition: opacity .3s ease, top .3s ease;
}

.fade-up-form-enter-from,
.fade-up-form-leave-to {
	opacity: 0;
	top: 15px;
}

.fade-up-slow-enter-active,
.fade-up-slow-leave-active {
	transition: opacity .3s ease .3s, top .3s ease .3s;
}

.fade-up-slow-enter-from,
.fade-up-slow-leave-to {
	opacity: 0;
	top: 15px;
}

.modal-container {
	position: fixed;
	z-index: 99999;
}

.container,
.content {
	height: 70vh;
}

.cards {
	height: calc(100% - 74px);
	margin: 0;
	padding: var(--padding);

	overflow: hidden;

	position: relative;
	z-index: 9999;
}

@media (min-width: 1024px) {
	.cards {
		width: 100%;
		max-width: 1100px;
		height: auto;
		margin: 0 auto 0;
		display: flex;
		flex-wrap: wrap;
		/*overflow: auto;*/
	}
}

.rest-a-little {
	width: calc(100vw - 40px);
	height: 100vw;
	margin-top: 0;

	box-sizing: border-box;

	position: absolute;
	left: 20px;
	top: 80px;
	z-index: 999;
	text-align: center;
}

@media (min-width: 1024px) {
	.rest-a-little {
		display: none;
	}
}

.rest-a-little .icon {
	font-size: 80px;
}

.rest-a-little p {
	font-size: 40px;
}

.rest-a-little__border {
	display: block;
	width: calc(100vw - 40px);
	height: 100vw;

	border: 5px dashed var(--color-black);
	border: none;
	border-radius: 30px;

	transform: scale(.9);

	box-sizing: border-box;

	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 999;
}

.actions {
	display: flex;
	flex-wrap:  wrap;
	justify-content: center;
	margin-top: 40px;
}

.actions .left,
.actions .right,
.actions .middle {
	opacity: .8;
	transition: opacity .3s ease, transform .3s ease;
	font-size: 45px;
	transform:  scale(0.75);
}

.actions .left[disabled],
.actions .right[disabled],
.actions .middle[disabled] {
	opacity: .2;
	pointer-events: none;
	filter: grayscale(1);
}

.actions.left .right,
.actions.left .middle,
.actions.right .left,
.actions.right .middle,
.actions.middle .left,
.actions.middle .right {
	opacity: .15;
}

.actions.left .left,
.actions.right .right,
.actions.middle .middle {
	opacity: 1;
	transform:  scale(0.95);
}

button {
	margin: 20px 0px;
	width: 25vw;
	height: 25vw;
	border-radius: 50%;
	border: none;
	padding: 0;
	appearance: none;

	background-color: var(--color-grey);
	color: white;
	border: 3px solid var(--color-black);
}
</style>