<template>
	<IonPage>
		<div class="no-scroll">
			<div class="page-container">
				<transition name="fade-up">
					<div class="jobs" v-if="isActive">
						<div class="jobs-inner">
							<transition name="fade-up">
								<div v-if="showPage">
									<cards :list="list"></cards>
								</div>
							</transition>
						</div>
					</div>
				</transition>

				<div class="job-modal">
					<div v-if="activeJobCard != null" class="modal-container">
						<Modal v-show="activeJobCard != null" :modifierClass="'full user'" @close="closeModal">
							<template v-slot:header>
							</template>

							<template v-slot:body>
								<div class="job-modal-data">
									<div class="job-modal-data-aside">
										<h1>{{activeJobCard.title}}</h1>

										<p>{{activeJobCard.companyName}}<br>
										{{activeJobCard.location}}<br>
										{{activeJobCard.type}}</p>

										<p>Ansøgningsfrist:<br>
										TODO: xx.xx.xxxx</p>

									</div>
									<div class="job-modal-data-description">
										<h3>Jobbeskrivelse:</h3>
										<p v-if="activeJobCard.description">{{activeJobCard.description}}</p>

										<h3>Kandidatbeskrivelse:</h3>
										<p v-if="activeJobCard.candidateDescription">{{activeJobCard.candidateDescription}}</p>

										<h3>Virksomhedsbeskrivelse:</h3>
										<p v-if="activeJobCard.companyDescription">{{activeJobCard.companyDescription}}</p>
									</div>
								</div>
							</template>

							<template v-slot:footer>
								<div class="desktop-job-actions">
									<button @click="applyJob" class="apply-btn">Apply</button>
									<button @click="discardJob" class="discard-btn">Discard</button>
								</div>
							</template>
						</Modal>
					</div>
				</div>


			</div>
		</div>
	</IonPage>
</template>

<script>
import events from '../events';
import cards from '../components/cards.vue';
import { mapState } from 'vuex';
import { store } from "../store/store";
import Modal from '../components/modal.vue';

import {
    IonPage,
    IonContent
} from '@ionic/vue';

import useFirebaseAuth from '../hooks/firebase-auth';

const {
	dbGetAllJobs,
	dbSetUsersJobStatus
} = useFirebaseAuth();

export default  {
	name: 'Jobs',
	components: {
		cards,
		Modal,
        IonPage,
        // IonContent
	},
	props: {
		list: String
	},
	data: () => {
		return {
			showPage: false,
			renderComponent: true,
		}
	},
	watch: {
		list: function () {
			this.forceRerender();
		}
	},
	computed: {
		...mapState([
			'user',
			'page',
			'jobs',
			'userData',
			'activeJobCard'
		]),
		isActive() {
			if (this.page) {
				return this.page.split('/')[1] == 'jobs';
			} else {
				return false;
			}
		}
	},
	mounted() {
		setTimeout(() => {
			this.showPage = true;
		}, 200);

		events().emitter.on('setCard', (payload) => {
			const chosenIndex = this.jobs.findIndex(x => x.id === payload.id);
		});
	},
	created() {
		if (!this.user) {
			this.$router.push({ path: '/' });
		}

		dbGetAllJobs((loaded) => {
			events().emitter.emit('jobsLoaded', true);
		});

		events().emitter.on('setCard', (payload) => {
			if (this.user.uid) {
				if (payload.list == 'apply') {
					dbSetUsersJobStatus(this.user.uid, payload.id, 2);
				} else {
					dbSetUsersJobStatus(this.user.uid, payload.id, 0);
				}
				
			}
		});

		events().emitter.on('noMoreCards', (payload) => {
			if (payload) {
				this.$data.noMoreCards = true;
			}
		});

		setTimeout(() => {
			this.showPage = true;
		}, 200);
	},
	methods: {
		getJob(id) {
			return this.jobs.find(x => x.id === id);
		},
		closeModal() {
			store.commit('setActiveJobCard', null);
		},
		applyJob() {
			events().emitter.emit('setCard', {
				'save': true,
				'list': 'apply',
				'id': this.activeJobCard.id,
				'removeFromList': true
			});

			store.commit('setActiveJobCard', null);
		},
		discardJob() {
			events().emitter.emit('setCard', {
				'save': false,
				'id': this.activeJobCard.id,
				'removeFromList': true
			});

			store.commit('setActiveJobCard', null);
		}
	}
}
</script>
<style scoped>
.no-scroll,
.page-container,
.jobs,
.jobs-inner,
.jobs-inner > div {
	height: 100%;
}
.jobs {
}

.jobs-inner {
	transform: translateY(0px);
}

.page-container {
	overflow: hidden;
	padding-top: 0;
}

button {
	display: block;
	
	padding:  14px;
	appearance: none;
	background-color: black;
	border-radius: 4px;
	color: white;
	font-size: 20px;
}

.reset-button {
	position: fixed;
	z-index: 99999999999;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
}

.store-button {
	position: fixed;
	z-index: 99999999999;
	bottom: 20px;
	left: 20px;
	background-color: transparent;
	border: none;
	font-size: 40px;
	padding: 0;
}

.store {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999999999;
	width: 100%;
	height: 100%;

	overflow: auto;
}

.store p,
.store h2 {
	color: black;
}

.store h2 {
	padding: 0;
	margin: 60px 0 20px;
}

.store p {
	padding: 0 0 10px;
}

.store img {
	display: block;
	width: 100%;
	max-height: 170px;
	object-fit: cover;
	object-position: center;
}

.store ul {
	display: block;
	padding: 0;
	margin: 0;
}

.store-content {
	box-sizing: border-box;
	width: 100%;
	padding: 80px 20px 20px;
}

.store ul li {
	display: block;
	padding: 20px 0;
	margin: 0;
	color: black;
	border-bottom: 2px solid black;
}

.store ul li:first-child {
	padding-top: 0;
}

.store-close {
	top: 20px;
	right: 20px;
	position: fixed;
	background-color: transparent;
	border: none;
	font-size: 40px;
	padding: 0;
}

.desktop-job-actions {
	position: relative;
}

.discard-btn,
.apply-btn {
	margin-bottom: 20px;
	cursor: pointer;
	display: inline-block;
	width: auto;
	text-transform: uppercase;
	padding: 10px 24px;
	border-radius: 20px;
	margin-left: 15px;
	margin-bottom: 0;
}

.job-modal {
	display: none;
}

@media (min-width: 1024px) {
	.job-modal {
		display: block;
	}
	
	.job-modal-data {
		height: 100%;
		display: flex;
		flex-direction: row;
	}

	.job-modal-data-aside {
		min-width: 33%;
		padding: 40px;
		color: var(--color-white);
		background-color: var(--color-black);
	}

	.job-modal-data-description {
		/*width: 67%;*/
		padding: 40px;
		flex-grow: 1;
	}
}
</style>